import React, { useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useUser } from "../UserContext";
import { Link } from "react-router-dom";  // Importa Link desde react-router-dom
import axios from "axios";
import HolaC from "../holac";

const Aconfi = () => {
    const { loggedInUser } = useUser();
  const [cuit, setCuit] = useState("");
  const [nombreNegocio, setNombreNegocio] = useState("");
  const [nombrePropietario, setNombrePropietario] = useState("");
  const [dni, setDni] = useState("");
  const [puntoVenta, setPuntoVenta] = useState("");
  const [tipoContribuyente, setTipoContribuyente] = useState("");
  const [ingresosBrutos, setIngresosBrutos] = useState("");
  const [fechaInicioActividades, setFechaInicioActividades] = useState("");
  const [domicilioComercial, setDomicilioComercial] = useState("");
  let username=loggedInUser;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/obtenerafip`);
        const data = response.data;
        setCuit(data.cuit);
        setNombreNegocio(data.nombreNegocio);
        setNombrePropietario(data.nombrePropietario);
        setDni(data.dni);
        setPuntoVenta(data.puntoVenta);
        setTipoContribuyente(data.tipoContribuyente);
        setIngresosBrutos(data.ingresosBrutos);
        setFechaInicioActividades(data.fechaInicioActividades);
        setDomicilioComercial(data.domicilioComercial);
      } catch (error) {
        console.error('Error al obtener la configuración:', error);
      }
    };

    fetchData();
  }, [loggedInUser]);




  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Realizar la solicitud POST con Axios
      await axios.post(`${HolaC.apiUrl}/${username}/guardarafip`, {
        cuit,
        nombreNegocio,
        nombrePropietario,
        dni,
        puntoVenta,
        tipoContribuyente,
        ingresosBrutos,
        fechaInicioActividades,
        domicilioComercial
      });
  
      // Mostrar una alerta de éxito
      alert('Los datos se guardaron correctamente');
    
  
    } catch (error) {
      console.error('Error al guardar los datos:', error);
      // Manejar el error
    }
  };
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "cuit":
        setCuit(value);
        break;
      case "nombreNegocio":
        setNombreNegocio(value);
        break;
      case "nombrePropietario":
        setNombrePropietario(value);
        break;
      case "dni":
        setDni(value);
        break;
      case "puntoVenta":
        setPuntoVenta(value);
        break;
      case "tipoContribuyente":
        setTipoContribuyente(value);
        break;
      case "ingresosBrutos":
        setIngresosBrutos(value);
        break;
      case "fechaInicioActividades":
        setFechaInicioActividades(value);
        break;
      case "domicilioComercial":
        setDomicilioComercial(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="container-fluid" style={{ background: 'linear-gradient(to right, #5e45b0, #8d24aa)' }}>
      <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="col-md-6">
          <img 
            src="/afiparg.png" 
            alt="AFIP Logo" 
            style={{ 
              display: 'block',
              margin: '0 auto',
              width: '300px', 
              height: '300px',
            }} 
          />

          <h2 className="text-center mb-4 text-white">Configuración de AFIP</h2>
          <form onSubmit={handleSubmit}>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="cuit" className="form-label text-white">CUIT:</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="cuit"
                  name="cuit"
                  value={cuit}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="nombreNegocio" className="form-label text-white">Nombre del negocio:</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="nombreNegocio"
                  name="nombreNegocio"
                  value={nombreNegocio}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="nombrePropietario" className="form-label text-white">Nombre del propietario:</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="nombrePropietario"
                  name="nombrePropietario"
                  value={nombrePropietario}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="dni" className="form-label text-white">DNI:</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="dni"
                  name="dni"
                  value={dni}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="puntoVenta" className="form-label text-white">Punto de venta:</label>
                <input
                  type="number"
                  className="form-control form-control-sm"
                  id="puntoVenta"
                  name="puntoVenta"
                  value={puntoVenta}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="tipoContribuyente" className="form-label text-white">Tipo de contribuyente:</label>
                <select
                  className="form-select form-select-sm"
                  id="tipoContribuyente"
                  name="tipoContribuyente"
                  value={tipoContribuyente}
                  onChange={handleInputChange}
                >
                  <option value="">Seleccione...</option>
                  <option value="responsableInscripto">Responsable Inscripto</option>
                  <option value="monotributista">Monotributista</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="ingresosBrutos" className="form-label text-white">Ingresos Brutos:</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="ingresosBrutos"
                  name="ingresosBrutos"
                  value={ingresosBrutos}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="fechaInicioActividades" className="form-label text-white">Fecha de inicio de actividades:</label>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  id="fechaInicioActividades"
                  name="fechaInicioActividades"
                  value={fechaInicioActividades}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="domicilioComercial" className="form-label text-white">Domicilio Comercial:</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="domicilioComercial"
                  name="domicilioComercial"
                  value={domicilioComercial}
                  onChange={handleInputChange}
                />
              </div>
            </div>
<button type="submit" className="btn btn-primary">Guardar</button>
</form>
<br></br>
<Link to={`/app/${loggedInUser}/configuracion`} className="btn btn-secondary btn-lila ms-2">
        Volver
      </Link>
  
</div>
</div>
</div>
);
};

export default Aconfi;