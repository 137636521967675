import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../UserContext";
import HolaC from "../holac";


const CategoriasM = () => {
  const { loggedInUser} = useUser();
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [editingCategories, setEditingCategories] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  let username=loggedInUser;

  const containerStyle = {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(to bottom right, #6a5acd, #003366)',
    padding: '20px',
    borderRadius: '8px',
  };
  console.log('username from MainComponent',username);  
  useEffect(() => {
    // Obtener las categorías al cargar el componente
    axios.get(`${HolaC.apiUrl}/${username}/categories`)
      .then(response => {
        setCategories(response.data);
        console.log(username);
      })
      .catch(error => {
        console.error("Error fetching categories:", error,username);
        console.log(username);
      });
  }, []);

  const handleCategorySelection = (categoryId) => {
    if (isEditing) {
      // Lógica de selección en modo edición
      const isSelected = selectedCategories[categoryId];
      const updatedSelectedCategories = { ...selectedCategories, [categoryId]: !isSelected };
      setSelectedCategories(updatedSelectedCategories);

      const selectedCategoriesInEditMode = { ...editingCategories };

      if (updatedSelectedCategories[categoryId]) {
        // Si está seleccionado pero no está en la lista de edición, agregar a la lista con los datos actuales
        if (!selectedCategoriesInEditMode[categoryId]) {
          selectedCategoriesInEditMode[categoryId] = { ...categories.find(category => category._id === categoryId) };
        }
      } else {
        // Si no está seleccionado, quitar de la lista de edición
        delete selectedCategoriesInEditMode[categoryId];
      }

      setEditingCategories(selectedCategoriesInEditMode);
    } else {
      // Lógica de selección normal
      const isSelected = selectedCategories[categoryId];
      const updatedSelectedCategories = { ...selectedCategories, [categoryId]: !isSelected };
      setSelectedCategories(updatedSelectedCategories);
    }
  };

  const handleEditSelectedCategories = () => {
    setIsEditing(true);
  };

  const handleSaveChanges = async () => {
    try {
      const editedCategories = Object.values(editingCategories);
      await Promise.all(editedCategories.map(category =>
        axios.put(`${HolaC.apiUrl}/api/${username}/categories/${category._id}`, category)
      ));

      console.log("Cambios guardados exitosamente");
      alert("Cambios guardados exitosamente");
      setIsEditing(false);
      window.location.reload();
    } catch (error) {
      console.error("Error al guardar cambios:", error);
      alert("Error al guardar cambios");
    }
  };

  const handleDeleteSelectedCategories = async () => {
    try {
      const selectedIds = Object.keys(selectedCategories);
    
      if (selectedIds.length === 0) {
        alert("Seleccione al menos una categoría para eliminar.");
        return;
      }
    
      // Utiliza el primer ID de las categorías seleccionadas, puedes ajustar esto según tus necesidades
      const firstCategoryId = selectedIds[0];
    
      await axios.delete(`${HolaC.apiUrl}/${username}/categories/${firstCategoryId}`);
      
      console.log("Categoría eliminada exitosamente");
      alert("Categoría eliminada exitosamente");
      setSelectedCategories({});
      window.location.reload();
    } catch (error) {
      console.error("Error al eliminar categoría:", error);
      alert("Error al eliminar categoría");
    }
  }


  const handleInputChange = (e, field, categoryId) => {
    const updatedEditingCategories = { ...editingCategories };
    updatedEditingCategories[categoryId] = {
      ...updatedEditingCategories[categoryId],
      [field]: e.target.value,
    };
    setEditingCategories(updatedEditingCategories);
  };

  return (
    <div className="container mt-5" style={containerStyle}>
      <h2 style={titleStyle}>Categorias</h2>
      <div className="mb-3">
        <button className="btn btn-primary me-2" onClick={handleEditSelectedCategories}>
          Editar Categoría(s)
        </button>
        {isEditing && (
          <button className="btn btn-success me-2" onClick={handleSaveChanges}>
            Guardar Cambios
          </button>
        )}
        {Object.keys(selectedCategories).length > 0 && (
          <button className="btn btn-danger me-2" onClick={handleDeleteSelectedCategories}>
            Eliminar Categoría(s)
          </button>
        )}
      </div>
      <div style={{ overflowY: "auto", maxHeight: "400px" }}>

      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(category => (
            <tr key={category._id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedCategories[category._id]}
                  onChange={() => handleCategorySelection(category._id)}
                />
              </td>
              <td>
                {isEditing && selectedCategories[category._id] ? (
                  <input
                    type="text"
                    value={editingCategories[category._id]?.categoryName || ""}
                    onChange={(e) => handleInputChange(e, "categoryName", category._id)}
                  />
                ) : (
                  category.categoryName
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
}

const containerStyle = {
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  background: 'linear-gradient(to bottom right, #6a5acd, #003366)',
  padding: '20px',
  borderRadius: '8px',
};

const titleStyle = {
  color: '#fff',
  fontSize:'60px',
  
};

const tableStyle = {
  background: 'rgba(255, 255, 255, 0.0)',
  borderRadius: '8px',
};

const rowStyle = {
  borderBottom: '1px solid #ddd',
};

const imageStyle = {
  width: '50px',
  borderRadius: '50%',
};



export default CategoriasM;
