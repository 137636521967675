import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { useUser } from '../UserContext';
import HolaC from "../holac";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillAlt, faCashRegister, faMoneyCheckAlt,faShoppingBasket  } from '@fortawesome/free-solid-svg-icons'; // Importa el icono de ventas
import Ventash from './ventash';
import Mgastoshoy from './mgastoshoy';
import Stock from './stock';

const Cajasmovil = () => {
  const [metodosPago, setMetodosPago] = useState([]);
  const [ventas, setVentas] = useState([]);
  const { loggedInUser } = useUser();
  let username = loggedInUser;


  const texto1 = {
    color: 'white', // Establecer el color de texto a blanco
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.10)', // Agregar sombra al texto
    fontSize: '40px', // Aumentar el tamaño del texto a 24px
  };


  const estiloContenedor = {
    background: 'linear-gradient(to bottom right, #6a5acd, #003366)', /* Ajusta los colores según tu preferencia */
    padding: '20px', // Añade un espacio de relleno alrededor del contenido
  };

  useEffect(() => {
    // Obtener métodos de pago
    axios.get(`${HolaC.apiUrl}/${username}/obtenerMetodoPago`)
      .then(response => {
        setMetodosPago(response.data);
      })
      .catch(error => {
        console.error('Error al obtener los métodos de pago:', error);
      });

    // Obtener ventas del día de hoy
    const fechaHoy = new Date().toISOString().split('T')[0];

    axios.get(`${HolaC.apiUrl}/${username}/verpay2`)
      .then(response => {
        setVentas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener las ventas:', error);
      });
  }, []); // Dependencia vacía para ejecutar solo una vez al montar el componente

  // Función para generar datos para el gráfico de ventas por método de pago
  const generarDatosGraficoVentas = () => {
    const datos = [];

    // Iterar sobre los métodos de pago y contar las ventas para cada uno
    metodosPago.forEach(metodo => {
      const ventasMetodo = ventas.filter(venta => venta.metodoPago === metodo.nombre);

      datos.push({
        metodoPago: metodo.nombre,
        ventas: ventasMetodo.length,
      });
    });

    return datos;
  };

  const generarDatosGraficoCajas = () => {
    const datos = [];

    // Iterar sobre los métodos de pago y sumar el total para cada uno
    metodosPago.forEach(metodo => {
      const ventasMetodo = ventas.filter(venta => venta.metodoPago === metodo.nombre);

      // Calcular la ganancia total acumulada para el método de pago actual
      const gananciaTotal = ventasMetodo.reduce((total, venta) => total + venta.total, 0);

      datos.push({
        caja: metodo.nombre,
        ventas: gananciaTotal,
      });
    });

    return datos;
  };

  return (
    <div style={estiloContenedor}>
      <h2  style={texto1}>MIS CAJAS   <FontAwesomeIcon icon={faMoneyBillAlt} /></h2>

      <BarChart width={600} height={400} data={generarDatosGraficoVentas()}>
        <XAxis dataKey="metodoPago" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="ventas" fill="#82ca9d" />
      </BarChart>

      <BarChart width={600} height={400} data={generarDatosGraficoCajas()}>
        <XAxis dataKey="caja" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="ventas" fill="#7a5e8c" />
      </BarChart>
      <h2 style={texto1}>
        MIS VENTAS <FontAwesomeIcon icon={faCashRegister} /> {/* Agrega el icono de ventas */}
      </h2>
<Ventash></Ventash>
      <br></br>
    
      <h2 style={texto1}>
        MIS GASTOS <FontAwesomeIcon icon={faMoneyCheckAlt} /> {/* Cambia el icono para representar los gastos */}
      </h2>
      <Mgastoshoy></Mgastoshoy>
      <br></br>
      <h2 style={texto1}>
        PRODUCTOS RESUMEN <FontAwesomeIcon icon={faShoppingBasket} /> {/* Cambia el icono para representar los productos */}
      </h2>
      <br></br>
      <Stock></Stock>
      <br></br>
    </div>
    
  );
};

export default Cajasmovil;
