import React, { useState, useEffect } from "react";
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Mgastoshoy from "./mgastoshoy";
import { useUser } from "../UserContext";
import HolaC from "../holac";


const MostrarGastos = () => {
  const [gastos, setGastos] = useState([]);
  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    // Obtener los gastos del backend al cargar el componente
    axios.get(`${HolaC.apiUrl}/${username}/Ogastos`)
      .then(response => {
        setGastos(response.data);
      })
      .catch(error => {
        console.error("Error al obtener los gastos:", error);
      });
  }, []);

  // Procesar los datos para contar los gastos por día del mes
  const obtenerGastosPorDia = () => {
    const gastosPorDia = {};

    gastos.forEach(gasto => {
      const dia = new Date(gasto.fecha).getDate();
      gastosPorDia[dia] = (gastosPorDia[dia] || 0) + gasto.monto;
    });

    // Convertir los datos a un formato compatible con Recharts
    const datosRecharts = Object.keys(gastosPorDia).map(dia => ({
      dia: parseInt(dia),
      monto: gastosPorDia[dia]
    }));

    return datosRecharts;
  };

  // Calcular la suma total de los gastos
const sumaTotalGastos = gastos.length > 0 ? gastos.reduce((total, gasto) => total + gasto.monto, 0) : 0;

  return (
    <div className="mostrar-gastos-container">
      <br />
      <div className="content-container">
        <div className="bar-chart-container">
          <h2>Gastos por día del mes - Total: ${sumaTotalGastos}</h2>
          <BarChart width={600} height={300} data={obtenerGastosPorDia()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dia" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="monto" fill="#8884d8" />
          </BarChart>
        </div>
        <div className="mgastoshoy-container">
          <Mgastoshoy />
        </div>
      </div>
    </div>
  );
};

export default MostrarGastos;
