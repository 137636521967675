import React, { useState } from "react";
import axios from "axios";
import { useUser } from "../UserContext";
import HolaC from "../holac";



const Metodoc1 = () => {
  const { loggedInUser} = useUser();

  const [metodoPago, setMetodoPago] = useState("");
  let username=loggedInUser;


  const handleSubmit = (e) => {
    e.preventDefault();

    // Validación básica
    if (!metodoPago) {
      alert("Por favor, ingrese el nombre del método de pago");
      return;
    }

    // Enviar el método de pago al servidor
    axios
      .post(`${HolaC.apiUrl}/${username}/guardarmetodo1`, {
        metodoPago: metodoPago,
      })
      .then((response) => {
        alert("Método de pago guardado exitosamente");
        setMetodoPago(""); // Limpiar el input después de guardar
        window.location.reload();

      })
      .catch((error) => {
        console.error("Error al guardar el método de pago:", error);
        alert("Ocurrió un error al guardar el método de pago", error);
      });
  };

  return (       
    <div className="alargar" style={{ width: '80%', margin: '0 auto' }}>
              <br></br>
              <br></br>

      <div className="nuevo">
        <h1 className="text-white">Cargar Método de Pago</h1>

        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-6">
            <label htmlFor="metodoPago" className="form-label text-white">Nombre del Método de Pago:</label>
            <input
              type="text"
              className="form-control"
              id="metodoPago"
              value={metodoPago}
              onChange={(e) => setMetodoPago(e.target.value)}
              required
            />
          </div>

          <div className="col-12 mt-3">
            <button type="submit" className="btn btn-primary">Guardar Método de Pago</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Metodoc1;
