import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './login';
import Registration from './registro';
import './App.css';
import Administrador from './administrador';
import Cargar from './productos/cargar';
import Proveedores from './proveedores/proveedores';
import Categorias from './categorias/categorias';
import Cajero from './cajero/cajero';
import Punto from './puntodeventa/punto';
import G from './gastos/g';
import CG from './gastos/cargargasto/cg';
import Ventas from './ventas/ventas';
import Confi from './configuracion/confi';
import Metodo1 from './metodopago/metodo';
import { UserProvider, LoggedInUser } from './UserContext';
import Esta from './estadisticas/esta';
import Confi1 from './configuracion/confi';
import Aconfi from './afip/Aconfi';
import Movil from './Movil/Movil1';

function App() {
  const loggedInUser = LoggedInUser();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileDevice = typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
      setIsMobile(isMobileDevice);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/:username/Registro" element={<Registration />} />
          <Route path={`/app/${loggedInUser}/Administrador`} element={isMobile ? <Movil /> : <Administrador />} />
          <Route path={`/app/${loggedInUser}/cargarproducto`} element={<Cargar />} />
          <Route path={`/app/${loggedInUser}/Proveedores`} element={<Proveedores />} />
          <Route path={`/app/${loggedInUser}/categorias`} element={<Categorias />} />
          <Route path={`/app/${loggedInUser}/Cajero`} element={<Cajero />} />
          <Route path={`/app/${loggedInUser}/puntoventa`} element={<Punto />} />
          <Route path={`/app/${loggedInUser}/gastos`} element={<G />} />
          <Route path={`/app/${loggedInUser}/cargargastos`} element={<CG />} />
          <Route path={`/app/${loggedInUser}/ventas`} element={<Ventas />} />
          <Route path={`/app/${loggedInUser}/configuracion`} element={<Confi1 />} />
          <Route path={`/app/${loggedInUser}/metodospagos`} element={<Metodo1 />} />
          <Route path={`/app/${loggedInUser}/estadisticas`} element={<Esta />} />
          <Route path={`/app/${loggedInUser}/Afipconfi`} element={<Aconfi />} />
          <Route path={`/app/${loggedInUser}/Movil`} element={<Movil />} />


        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
