import React, { useEffect, useState } from "react";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import VentasnetaMensual from "./ventasntasmensual";
import VentasNetasTotal from "./ventastotal";
import { useUser } from "../UserContext";
import HolaC from "../holac";

const Ventanetahoy = () => {
  const [ventasHoy, setVentasHoy] = useState([]);
  const [totalVentasNetas, setTotalVentasNetas] = useState(0);
  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    const fetchVentasHoy = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${username}/ventas-hoy`);
        setVentasHoy(response.data);

        // Calcular el total de las ventas netas
        const total = response.data.reduce((accumulator, venta) => accumulator + venta.totalneto, 0);
        setTotalVentasNetas(total);
      } catch (error) {
        console.error('Error al obtener las ventas de hoy:', error);
      }
    };

    fetchVentasHoy();
  }, []);

  return (
    <div>
      <div style={{ width: '100%', height: 330 }}>
        <ResponsiveContainer>
          <BarChart
            data={ventasHoy}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="createdAt" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="totalneto" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <h3 style={{ textAlign: "center", color: "#8884d8", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}>
        <p>Total hoy ventas netas</p>
      <h2>Total ${totalVentasNetas.toFixed(2)}</h2>

      </h3>
      <br></br>
      <br></br>

      <VentasnetaMensual/>
<VentasNetasTotal/>
    </div>
  );
};

export default Ventanetahoy;
