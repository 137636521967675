import React, { useState, useEffect } from "react";
import axios from "axios";
import HolaC from "../holac";
import { useUser } from "../UserContext";
import { Link } from "react-router-dom";  // Importa Link desde react-router-dom


const Confi = () => {
  const { loggedInUser } = useUser();
  const [configuracion, setConfiguracion] = useState({
    nombreLocal: "",
    direccion: "",
    telefono: "",
    enviarPorWhatsApp: false,
    enviarPorEmail: false,
    activarConexionAF: false,
  });

  useEffect(() => {
    // Realizar una solicitud GET para obtener la configuración actual al cargar el componente
    const obtenerConfiguracion = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${loggedInUser}/obtenercon`);
        if (response.status === 200) {
          setConfiguracion(response.data);
        } else {
          console.error("Error al obtener la configuración:", response.data.message);
        }
      } catch (error) {
        console.error("Error al obtener la configuración:", error);
      }
    };

    obtenerConfiguracion();
  }, [loggedInUser]);

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setConfiguracion((prevConfig) => ({
      ...prevConfig,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSaveConfiguracion = async () => {
    try {
      // Enviar solo el objeto 'configuracion' al servidor
      const response = await axios.post(`${HolaC.apiUrl}/${loggedInUser}/guardarcon`, { configuracion });

      if (response.status === 200) {
        alert("Configuración guardada exitosamente");
        window.location.reload();

      } else {
        alert("Error al guardar la configuración");
      }
    } catch (error) {
      console.error("Error al guardar la configuración:", error);
      alert("Error al guardar la configuración");
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      // Procesar el archivo Excel aquí
    };
    reader.readAsArrayBuffer(file);
  };
  
  
  return (
    <div className="container mt-5">
      <h1 className="text-white mb-4">Configuración del Negocio</h1>
      <div className="mb-3">
        <label htmlFor="nombreLocal" className="form-label text-white">
          Nombre del Local
        </label>
        <input
          type="text"
          className="form-control"
          id="nombreLocal"
          onChange={handleInputChange}
          value={configuracion.nombreLocal}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="direccion" className="form-label text-white">
          Dirección
        </label>
        <input
          type="text"
          className="form-control"
          id="direccion"
          onChange={handleInputChange}
          value={configuracion.direccion}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="telefono" className="form-label text-white">
          Teléfono
        </label>
        <input
          type="text"
          className="form-control"
          id="telefono"
          onChange={handleInputChange}
          value={configuracion.telefono}
        />
      </div>
      <h1 className="text-white mb-4">Conexiones</h1>
      <div className="mb-3">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="enviarPorWhatsApp"
            onChange={handleInputChange}
            checked={configuracion.enviarPorWhatsApp}
          />
          <label className="form-check-label text-white" htmlFor="enviarPorWhatsApp">
            Enviar mensajes por WhatsApp
          </label>
        </div>
      </div>
      <div className="mb-3">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="enviarPorEmail"
            onChange={handleInputChange}
            checked={configuracion.enviarPorEmail}
          />
          <label className="form-check-label text-white" htmlFor="enviarPorEmail">
            Enviar avisos por correo electrónico
          </label>
        </div>
      </div>
      <div className="mb-3">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="activarConexionAF"
            onChange={handleInputChange}
            checked={configuracion.activarConexionAF}
          />
          <label className="form-check-label text-white" htmlFor="activarConexionAF">
            Activar conexión A.F.
          </label>
        </div>
        <input type="file" accept=".xlsx,.xls" onChange={handleFileUpload} />

      </div>
      <button className="btn btn-primary" onClick={handleSaveConfiguracion}>
        Guardar Configuración
      </button>

      <Link to={`/app/${loggedInUser}/administrador`} className="btn btn-secondary btn-lila ms-2">
        Volver
      </Link>
      <Link to={`/app/${loggedInUser}/Afipconfi`} className="btn btn-secondary btn-lila ms-2">
              Configuración Afip
            </Link>
    </div>
  );
};

export default Confi;
