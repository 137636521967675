import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../UserContext";
import HolaC from "../holac";


const GastosM = () => {
  const [cajeros, setCajeros] = useState([]);
  const [selectedCajeroId, setSelectedCajeroId] = useState(null);
  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    // Obtener los cajeros al cargar el componente
    axios.get(`${HolaC.apiUrl}/${username}/gasto2`)
      .then(response => {
        setCajeros(response.data);
      })
      .catch(error => {
        console.error("Error fetching cajeros:", error);
      });
  }, []);

  const handleCajeroSelection = (cajeroId) => {
    setSelectedCajeroId(cajeroId);
  };

  const handleDeleteSelectedCajero = async () => {
    try {
      if (!selectedCajeroId) {
        alert("Seleccione un gasto para eliminar.");
        return;
      }
  
      await axios.delete(`${HolaC.apiUrl}/${username}/gastos/${selectedCajeroId}`);
      
      console.log("Gasto eliminado exitosamente");
      alert("Gasto eliminado exitosamente");
      setSelectedCajeroId(null);
      window.location.reload();
    } catch (error) {
      console.error("Error al eliminar el gasto", error);
      alert("Error al eliminar el gasto");
    }
  }
  const containerStyle = {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(to bottom right, #6a5acd, #003366)',
    padding: '20px',
    borderRadius: '8px',
  };
  return (
    <div className="container mt-5" style={containerStyle}>
      <h2 style={titleStyle}>Gastos</h2>
      <div className="mb-3">
        <button className="btn btn-danger me-2" onClick={handleDeleteSelectedCajero}>
          Eliminar Gasto
        </button>
      </div>
      <div style={{ overflowY: "auto", maxHeight: "400px" }}>

      <table  className="table">
        <thead>
          <tr>
            <th></th>
            <th>Nombre Gasto</th>
             <th>Tipo de gasto</th>
          </tr>
        </thead>
        <tbody>
          {cajeros.map(cajero => (
            <tr key={cajero._id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedCajeroId === cajero._id}
                  onChange={() => handleCajeroSelection(cajero._id)}
                />
              </td>
              <td>{cajero.gastoName2}</td>
                <td>{cajero.gastoType}</td>
             
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
}

const titleStyle = {
  color: '#000',
  fontSize: '30px',
};

export default GastosM;
