import React, { useState, useEffect } from "react";
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useUser } from "../UserContext";
import HolaC from "../holac";

const Mgastoshoy = () => {
  const [gastos, setGastos] = useState([]);
  const { loggedInUser} = useUser();
  let username=loggedInUser;
  const texto1 = {
    color: 'white', // Establecer el color de texto a blanco
    textShadow: '1px 1px 2px rgba(0, 0, 0, 10)', // Agregar sombra al texto
    fontSize: '20px',
    textAlign: 'center', // Alinear el texto al centro
  };

  useEffect(() => {
    // Obtener los gastos del backend al cargar el componente
    axios.get(`${HolaC.apiUrl}/${username}/ghoy`)
      .then(response => {
        setGastos(response.data);
      })
      .catch(error => {
        console.error("Error al obtener los gastos:", error);
      });
  }, []);

  const sumaTotalGastos = gastos.length > 0 ? gastos.reduce((total, gasto) => total + gasto.monto, 0) : 0;

  return (
    <div>
      <h2 style={texto1}>Gastos por hora del día de hoy ${sumaTotalGastos}</h2>
      <BarChart width={600} height={300} data={gastos}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="hora" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="monto" fill="#8884d8" />
      </BarChart>

      <h3 style={{ textAlign: 'center', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
        <p style={texto1}>Gastos del día de hoy</p>
        Total hoy: $ {sumaTotalGastos}
        </h3>
      

    </div>
  );
};

export default Mgastoshoy;
