import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../UserContext";
import HolaC from "../holac";


const VentasM = () => {
  const { loggedInUser } = useUser();
  const username = loggedInUser;

  const [ventasDelMes, setVentasDelMes] = useState([]);
  const [filteredVentas, setFilteredVentas] = useState([]);
  const [selectedVentaId, setSelectedVentaId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [metodosPago, setMetodosPago] = useState([]);

  useEffect(() => {
    // Obtener todas las ventas y métodos de pago al cargar el componente
    fetchVentas();
    fetchMetodosPago();
    setSelectedMonth("0"); // Inicializar con "Todo el año"
  }, [username]);
  useEffect(() => {
    // Filtrar las ventas cuando el mes seleccionado cambia
    filterVentasByMonth(selectedMonth);
  }, [selectedMonth, ventasDelMes]);

  const handleVentaSelection = (ventaId) => {
    setSelectedVentaId(ventaId);
  };

  const handleSearch = () => {
    // Actualizar el filtro de ventas al hacer clic en el botón
    filterVentasByMonth(selectedMonth);
  };

  const fetchVentas = () => {
    // Hacer la solicitud al servidor para obtener las ventas según el mes seleccionado
    axios
      .get(`${HolaC.apiUrl}/${username}/ventasmostrar`, {
        params: { mes: selectedMonth },
      })
      .then((response) => {
        setVentasDelMes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching sales:", error);
      });
  };

  const fetchMetodosPago = () => {
    // Hacer la solicitud al servidor para obtener los métodos de pago
    axios
      .get(`${HolaC.apiUrl}/${username}/obtenerMetodoPago`)
      .then((response) => {
        setMetodosPago(response.data);
      })
      .catch((error) => {
        console.error("Error fetching payment methods:", error);
      });
  };

  const filterVentasByMonth = (month) => {
    // Filtrar las ventas según el mes y método de pago seleccionados
    const numericMonth = parseInt(month, 10);
    const filtered = ventasDelMes.filter((venta) => {
      const ventaMonth = new Date(venta.createdAt).getMonth() + 1; // Meses en JavaScript son 0-indexados
      const monthCondition = numericMonth === 0 || ventaMonth === numericMonth; // 0 significa "Todo el año"
      const methodCondition = selectedMethod === "" || venta.metodoPago === selectedMethod;
      return monthCondition && methodCondition;
    });
    setFilteredVentas(filtered);
  };
  
  const [selectedMethod, setSelectedMethod] = useState("");

  const titleStyle = {
    color: '#fff',
    fontSize:'60px',
    
  };
  
  const titleStyle2 = {
    color: '#fff',
    
  };
  const containerStyle = {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(to bottom right, #6a5acd, #003366)',
    padding: '20px',
    borderRadius: '8px',
  };

  return (
    <div   style={containerStyle}className="container mt-5">
      <h2 style={titleStyle} >Ventas del Mes </h2>
      <div className="mb-3">
        <label style={{ color: 'white' }} htmlFor="monthSelector" className="me-2">
          Selecciona un mes:
        </label>
        <select
          id="monthSelector"
          className="form-select"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
        >
          <option value="0">Todo el año</option>
          <option value="1">Enero</option>
          <option value="2">Febrero</option>
          <option value="3">Marzo</option>
          {/* Agrega opciones para los demás meses */}
        </select>

        <label htmlFor="methodSelector" className="ms-2" style={{ color: 'white' }}>
  Selecciona un método de pago:
</label>
        <select
          id="methodSelector"
          className="form-select"
          value={selectedMethod}
          onChange={(e) => setSelectedMethod(e.target.value)}
        >
          <option value="">Todos</option>
          {metodosPago.map((metodo) => (
            <option key={metodo._id} value={metodo.nombre}>
              {metodo.nombre}
            </option>
          ))}
        </select>

        <button className="btn btn-primary ms-2" onClick={handleSearch}>
          Buscar
        </button>
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Total</th>
              <th>Numero de venta</th>
              <th>Método de Pago</th>
              <th>Total Neto</th>
              <th>Fecha de Creación</th>
              {/* Agrega más columnas según los datos de tu JSON */}
            </tr>
          </thead>
          <tbody>
            {filteredVentas.map((venta) => (
              <tr key={venta._id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedVentaId === venta._id}
                    onChange={() => handleVentaSelection(venta._id)}
                  />
                </td>
                <td>${venta.total}</td>
                <td>{venta.paynumber}</td>
                <td>{venta.metodoPago}</td>
                <td>${venta.totalneto}</td>
                <td>{new Date(venta.createdAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VentasM;
