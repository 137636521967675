import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { useUser } from "../UserContext";
import HolaC from "../holac";


const Stock = () => {
  const [sinStock, setSinStock] = useState([]);
  const [enAviso, setEnAviso] = useState([]);
  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    // Fetch products from the API
    axios
      .get(`${HolaC.apiUrl}/${username}/products1`)
      .then((response) => {
        // Filter products with stock equal to 0
        const sinStockProducts = response.data.filter((product) => product.stock === 0);
        setSinStock(sinStockProducts);

        // Filter products with stock equal to 1
        const enAvisoProducts = response.data.filter((product) => product.stock === 1);
        setEnAviso(enAvisoProducts);
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  const renderTable = (data, title, tableStyle) => {
    return (
      <div>
        <h2>{title}</h2>
        <table className="table table-striped" style={tableStyle}>
          <thead>
            <tr>
              <th scope="col">Productos</th>
              <th scope="col">Stock</th>
              <th scope="col">Proveedor</th>

            </tr>
          </thead>
          <tbody>
            {data.map((product) => (
              <tr key={product.id}>
                <td>{product.productName}</td>
                <td>{product.stock}</td>
                <td>{product.provider}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const commonTableStyle = {
    backgroundColor: '#8884d8',
    color: '#fff',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
  };

  return (
    <div>
      {renderTable(sinStock, "Productos Sin Stock", commonTableStyle)}
      {renderTable(enAviso, "Productos En Aviso", commonTableStyle)}
    </div>
  );
};

export default Stock;
