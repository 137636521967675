import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import Aos from 'aos';
import Abajo from '../abajo';
import PuntoPantalla from './puntopantalla';
import PuntoProductos from './puntoproductos';

const Punto = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [total, setTotal] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [discountType, setDiscountType] = useState('percentage');
  const [discountValue, setDiscountValue] = useState(0);
  const [reloadProducts, setReloadProducts] = useState(false);
  const [divKey, setDivKey] = useState(1);

  useEffect(() => {
    if (reloadProducts) {
      setDivKey((prevKey) => prevKey + 1);
      setReloadProducts(false);
    }
  }, [reloadProducts]);

  const handleProductPrice = (price) => {
    setTotal((prevTotal) => prevTotal + price);
  };

  const handleProductSelection = (product) => {
    setSelectedProduct(product);
  };

  const handleNewSale = () => {
    let discountedTotal = total;

    if (discountType === 'percentage') {
      discountedTotal *= 1 - discountValue / 100;
    } else {
      discountedTotal -= discountValue;
    }

    setTotal(0);
    setTotal(discountedTotal);
  };

  const handleDiscountTypeChange = (type) => {
    setDiscountType(type);
  };

  const handleDiscountValueChange = (value) => {
    setDiscountValue(value);
  };

  return (
    <div className="cargar-page" data-aos="flip-left">
      <div key={divKey} className="cargar-container" data-aos="fadrie-ght"></div>
      <PuntoProductos 
        key={divKey} // Asigna la misma clave para forzar la actualización
        handleProductPrice={handleProductPrice} 
        handleProductSelection={handleProductSelection}  
        reloadProducts={() => setReloadProducts(true)}
      />
      <div className="content" data-aos="flip-right">
        <br></br>
        <PuntoPantalla
          total={total}
          selectedProduct={selectedProduct}
          onNewSale={handleNewSale}
          discountType={discountType}
          discountValue={discountValue}
          onDiscountTypeChange={handleDiscountTypeChange}
          onDiscountValueChange={handleDiscountValueChange}
          setSelectedProduct={setSelectedProduct} 
          setTotal={setTotal}
          data-aos="fade-right"
          setReloadProducts={setReloadProducts} 
        />
        <div className="productos" data-aos="flip-right"></div>
      </div>
    </div>
  );
};

export default Punto;
