import React, { useState, useEffect } from 'react';
import VentasMensual from './ventasmensual';
import Ventash from './ventash';
import ProductosV from './productosv';
import Stock from './stock';
import GraficoGananciaBruta from './gananciabruta';
import Ventanetahoy from './ventanetahoy';
import Cajas from './cajas';


const Ventashoy =() =>{


  return (

    <div style={{ background: 'linear-gradient(to right, #fff, #8884d8)', padding: '20px', display: 'flex' }}>
      <div style={{ marginRight: '20px' }}>
 <Ventash/>

 <GraficoGananciaBruta/>



      </div>
      <Ventanetahoy/>
 
      <Stock/>
      <Cajas/>

      <div>

      </div>

    </div>
  );
}


export default Ventashoy;
