import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../UserContext';
import HolaC from "../holac";


const GastosC = () => {
  const [gastoName, setGastoName] = useState('');
  const [gastoType, setGastoType] = useState('');
  const { loggedInUser} = useUser();
  let username=loggedInUser;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${HolaC.apiUrl}/${username}/gasto`, {
        gastoName2:gastoName,
        gastoType:gastoType,
      });

      console.log('Gasto saved successfully!', response.data);
      alert('Gasto guardado exitosamente');
      window.location.reload();
    } catch (error) {
      console.error('Error saving Gasto:', error);
      alert('Error al guardar el Gasto');
    }
  };

  return (
    <div className="alargar" style={{ width: '80%', margin: '0 auto' }}>
      <div className="nuevo">
        <br />
        <br />
        <h1 className="text-white">Cargar Gastos</h1>

        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-6">
            <label htmlFor="GastoName" className="form-label text-white">Nombre del Gasto</label>
            <input
              type="text"
              className="form-control"
              id="GastoName"
              value={gastoName}
              onChange={(e) => setGastoName(e.target.value)}
              required
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="GastoType" className="form-label text-white">Tipo del gasto</label>
            <select
              className="form-control"
              id="GastoType"
              value={gastoType}
              onChange={(e) => setGastoType(e.target.value)}
              required
            >
              <option value="" disabled>Select...</option>
              <option value="diario">Diario</option>
              <option value="mensual">Mensual</option>
              <option value="esporadico">Esporádico</option>
            </select>
          </div>

          <div className="col-12 mt-3">
            <button type="submit" className="btn btn-primary">Guardar Gasto</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GastosC;
