import React, { useState, useEffect } from 'react';
import Ventash from '../graficos/ventash';
import Stock from '../graficos/stock';
import GraficoGananciaBruta from '../graficos/gananciabruta';
import Ventanetahoy from '../graficos/ventanetahoy';
import Cajas from '../graficos/cajas';

const Esta =() =>{


  return (

    <div style={{ background: 'linear-gradient(to right, #fff, #8884d8)', padding: '20px', display: 'flex' }}>
      <div style={{ marginRight: '20px' }}>
 <Ventash/>

 <GraficoGananciaBruta/>



      </div>
      <Ventanetahoy/>
 
      <Cajas/>
    




      <div>
   

      </div>

    </div>
  );
}


export default Esta;
