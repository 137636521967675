// Registration.js
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import HolaC from './holac';

const Registration = () => {
  const [username, setUsername] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('cliente'); // Valor predeterminado

  const handleRegistration = (e) => {
    e.preventDefault();

    axios
      .post(`${HolaC.apiUrl}/register`, {
        username, // Cambiado de firstName a username
        lastName,
        email,
        password,
        userType,
      })
      .then((response) => {
        console.log(response.data);
        // Aquí puedes manejar la respuesta exitosa
      })
      .catch((error) => {
        console.error('Error en la solicitud:', error);
        // Aquí puedes manejar el error
      });
  };

  return (
    <div className="login-page">
      <Container className="mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div
              className="login-container"
              style={{
                backgroundColor: '#6a5acd',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Form onSubmit={handleRegistration}>
                <Form.Group controlId="formUsername">
                  <Form.Label
                    style={{
                      color: '#ffffff',
                      fontFamily: 'Noto Sans, sans-serif',
                      fontWeight: 500,
                    }}
                  >
                    Usuario
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa tu usuario"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formLastName">
                  <Form.Label
                    style={{
                      color: '#ffffff',
                      fontFamily: 'Noto Sans, sans-serif',
                      fontWeight: 500,
                    }}
                  >
                    Apellido
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa tu apellido"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label
                    style={{
                      color: '#ffffff',
                      fontFamily: 'Noto Sans, sans-serif',
                      fontWeight: 500,
                    }}
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Ingresa tu email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Label
                    style={{
                      color: '#ffffff',
                      fontFamily: 'Noto Sans, sans-serif',
                      fontWeight: 500,
                    }}
                  >
                    Contraseña
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Ingresa tu contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formUserType">
                  <Form.Label
                    style={{
                      color: '#ffffff',
                      fontFamily: 'Noto Sans, sans-serif',
                      fontWeight: 500,
                    }}
                  >
                    Tipo de usuario
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                  >
                    <option value="cliente">Cliente</option>
                    <option value="administrador">Administrador</option>
                  </Form.Control>
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  style={{
                    backgroundColor: '#4b0082',
                    borderColor: '#4b0082',
                    padding: '12px 20px',
                    transition: 'background-color 0.3s',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#003366';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '#4b0082';
                  }}
                >
                  Registrarse
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Registration;
