import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from "../UserContext";
import HolaC from "../holac";

const CategoriasC = () => {
  const [categoryName, setCategoryName] = useState(''); // Update to categoryName
  const { loggedInUser} = useUser();
  let username=loggedInUser;
  




  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${HolaC.apiUrl}/${username}/categories`, {
        categoryName, // Update to categoryName
      });

      console.log('Category saved successfully!', response.data);
      alert('Categoría guardada exitosamente');
      window.location.reload();
    } catch (error) {
      console.error('Error saving category:', error);
      alert('Error al guardar la categoría');
    }
  };

  return (
    <div className="alargar" style={{ width: '80%', margin: '0 auto' }}>
      <div className="nuevo">
        <br></br>
        <br></br>


      <h1 className="text-white">Cargar Categoria</h1>

        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-6">
            <label htmlFor="CategoryName" className="form-label text-white">Nombre de la categoria:</label>
            <input
  type="text"
  className="form-control"
  id="CategoryName"
  value={categoryName}  
  onChange={(e) => setCategoryName(e.target.value)}
  required
/>
          </div>

          <div className="col-12 mt-3">
            <button type="submit" className="btn btn-primary">Guardar Categoria </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CategoriasC;
