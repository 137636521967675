// Sidebar.js
import React from 'react';
import {
  FaHouseUser,
  FaBox,
  FaFolder,
  FaCashRegister,
  FaChartBar,
  FaDollarSign,
  FaClipboardList,
  FaCog,
  FaUserFriends,
  FaShoppingBag,
  FaFileInvoice,
} from "react-icons/fa";import { Link } from 'react-router-dom';
import { useUser } from './UserContext';

const Menu = ({ activeItem }) => {
const {loggedInUser}=useUser();
const menuItems = [
  { name: 'Inicio', icon: <FaHouseUser />, link: `/app/${loggedInUser}/administrador` },
  { name: 'Ventas', icon: <FaFileInvoice />, link: `/app/${loggedInUser}/ventas` },
  { name: 'Mètodos de pago', icon: <FaFileInvoice />, link: `/app/${loggedInUser}/metodospagos` },
  { name: 'Cargar Productos', icon: <FaBox />, link: `/app/${loggedInUser}/cargarproducto` },
  { name: 'Cargar Categorias', icon: <FaFolder />, link: `/app/${loggedInUser}/categorias`},
  { name: 'Punto de Venta', icon: <FaCashRegister />, link: `/app/${loggedInUser}/puntoventa` },
  { name: 'Estadísticas', icon: <FaChartBar />, link: `/app/${loggedInUser}/estadisticas`},
  { name: 'Cajeros', icon: <FaUserFriends />, link: `/app/${loggedInUser}/cajero`},
  { name: 'Proveedores', icon: <FaShoppingBag />, link: `/app/${loggedInUser}/proveedores` },
  { name: 'Gastos', icon: <FaDollarSign />, link: `/app/${loggedInUser}/gastos` },
  { name: 'Cargar Gastos', icon: <FaClipboardList />, link: `/app/${loggedInUser}/cargargastos` },
  { name: 'Configuración', icon: <FaCog />, link: `/app/${loggedInUser}/configuracion` },
];

  return (
    <div className="sidebar">
      <div style={{ textAlign: 'center' }}>
        <img
          src="/geniusgif.gif"
          alt="Logo"
          className="img-fluid mb-4"
          style={{
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            margin: '0 auto',
          }}
        />
      </div>
      {menuItems.map((item, index) => (
        <div key={index} className={`content ${activeItem === item.name.toLowerCase() ? 'active' : ''}`}>
          <Link
            to={item.link}
            style={{
              color: '#000000', // Default color
              textDecoration: 'none', // Remove underline
              display: 'flex', // Use flex to align items horizontally
              alignItems: 'center', // Center items vertically
              transition: 'color 0.3s', // Add smooth transition to color change
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.color = '#4b0082'; // Change color to lila on hover
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.color = '#000000'; // Change back to default color on mouse out
            }}
          >
            <div className="icon">
              {item.icon}
            </div>
            <div>
              {item.name}
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Menu;
