import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../UserContext";
import HolaC from "../holac";


const Mostrarproductos = () => {
  const [products, setProducts] = useState([]);
  const [nameSearchTerm, setNameSearchTerm] = useState("");
  const [skuSearchTerm, setSkuSearchTerm] = useState("");
  const [showZeroStock, setShowZeroStock] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [editingProducts, setEditingProducts] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [providers, setProviders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [priceIncrementType, setPriceIncrementType] = useState("amount");
const [priceIncrementValue, setPriceIncrementValue] = useState("");
const [priceIncrementType2, setPriceIncrementType2] = useState("amount");
const [priceIncrementValue2, setPriceIncrementValue2] = useState("");


const { loggedInUser} = useUser();
let username=loggedInUser;


  useEffect(() => {
    axios.get(`${HolaC.apiUrl}/${username}/products1`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error("Error fetching products:", error);
      });

    axios.get(`${HolaC.apiUrl}/${username}/providers`)
      .then(response => {
        setProviders(response.data);
      })
      .catch(error => {
        console.error("Error fetching providers:", error);
      });

    axios.get(`${HolaC.apiUrl}/${username}/categories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const filteredProducts = products.filter(product =>
    product.productName.toLowerCase().includes(nameSearchTerm.toLowerCase()) &&
    (product.sku && typeof product.sku === 'string') ? product.sku.toLowerCase().includes(skuSearchTerm.toLowerCase()) : true &&
    (showZeroStock ? product.stock === 0 : true) &&
    (!selectedProvider || product.provider === selectedProvider) &&
    (!selectedCategory || product.category === selectedCategory)
  );

  const handleProductSelection = (productId) => {
    console.log('Selected Product ID:', productId);
  
    const isSelected = selectedProducts[productId];
    const updatedSelectedProducts = { ...selectedProducts, [productId]: !isSelected };
    setSelectedProducts(updatedSelectedProducts);
  
    const selectedProductsInEditMode = { ...editingProducts };
  
    if (updatedSelectedProducts[productId]) {
      if (!selectedProductsInEditMode[productId]) {
        selectedProductsInEditMode[productId] = { ...products.find(product => product._id === productId) };
      }
    } else {
      delete selectedProductsInEditMode[productId];
    }
  
    setEditingProducts(selectedProductsInEditMode);
  
    // Apply price increment immediately when the product is selected
    /*if (updatedSelectedProducts[productId]) {
      applyPriceIncrement(productId);
    }*/
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedSelectedProducts = {};
    const updatedEditingProducts = {};
  
    filteredProducts.forEach(product => {
      updatedSelectedProducts[product._id] = !selectAll;
  
      if (!selectAll && !editingProducts[product._id]) {
        updatedEditingProducts[product._id] = { ...product };
      }
    });
  
    setSelectedProducts(updatedSelectedProducts);
    setEditingProducts(updatedEditingProducts);
  };

   

  const handleEditSelectedProducts = () => {
    setIsEditing(true);
  };

  const handleSaveChanges = async () => {
    try {
      const editedProducts = Object.values(editingProducts);
      await Promise.all(editedProducts.map(product =>
        axios.put(`${HolaC.apiUrl}/${username}/products/${product._id}`, product)
      ));

      console.log("Cambios guardados exitosamente");
      alert("Cambios guardados exitosamente");
      setIsEditing(false);
      window.location.reload();
    } catch (error) {
      console.error("Error al guardar cambios:", error);
      alert("Error al guardar cambios");
    }
  };

  const handleDeleteSelectedProducts = async () => {
    try {
      const selectedIds = Object.keys(selectedProducts);
  
      if (selectedIds.length === 0) {
        alert("Seleccione al menos un producto para eliminar.");
        return;
      }
  
      await axios.delete(`${HolaC.apiUrl}/${username}/products`, {
        data: { selectedProductIds: selectedIds },
      });
  
      console.log("Productos eliminados exitosamente");
      alert("Productos eliminados exitosamente");
      setSelectedProducts({});
      window.location.reload();
    } catch (error) {
      console.error("Error al eliminar productos:", error);
      alert("Error al eliminar productos");
    }
  };


  const handlePriceIncrementTypeChange = (e) => {
    const selectedValue = e.target.value;
    console.log('Selected Price Increment Type:', selectedValue);
    setPriceIncrementType(selectedValue);
  };

  const handlePriceIncrementTypeChange2 = (e) => {
    const selectedValue = e.target.value;
    console.log('Selected Price Increment Type:', selectedValue);
    setPriceIncrementType2(selectedValue);
  };




  const handlePriceIncrementValueChange = (e) => {
    const enteredValue = e.target.value;
  console.log('Entered Price Increment Value:', enteredValue);
  setPriceIncrementValue(enteredValue);
  };
  const handlePriceIncrementValueChange2 = (e) => {
    const enteredValue = e.target.value;
  console.log('Entered Price Increment Value:', enteredValue);
  setPriceIncrementValue2(enteredValue);
  };

  /*const applyPriceIncrement = (productId) => {
    const product = { ...editingProducts[productId] };
    let incrementValue = parseFloat(priceIncrementValue);
  
    if (isNaN(incrementValue)) {
      alert("Por favor, ingrese un valor numérico válido.");
      return;
    }
  
    if (priceIncrementType === "percentage") {
      incrementValue = (product.costPrice * incrementValue) / 100;
      product.displayIncrementValue = `${priceIncrementValue}%`;
    } else {
      product.displayIncrementValue = `${incrementValue}`;
    }
  
    product.costPrice = (parseFloat(product.costPrice) + incrementValue).toFixed(2);
  
    // Update the editingProducts state with the modified product
    setEditingProducts(prevEditingProducts => ({
      ...prevEditingProducts,
      [productId]: product
    }));
  };*/
  
  // Use the applyPriceIncrement function in handleApplyPriceIncrement
// Modifica la función handleApplyPriceIncrement
const handleApplyPriceIncrement = async () => {
  try {
    // Apply price increment for all selected products
    const editedProducts = Object.values(editingProducts);

    // Use Promise.all to wait for all axios.put requests to complete
    await Promise.all(editedProducts.map(async (product) => {

     
            await axios.put(`${HolaC.apiUrl}/${username}/products/increment-price/${product._id}`, {

        priceIncrementType: priceIncrementType,
        priceIncrementValue: priceIncrementValue,
      });

      // Optionally, update the product in the local state after the increment
      // ...

      // Uncomment the following line if you want to apply the increment locally
      // applyPriceIncrement(product._id);
    }));

    console.log("Cambios guardados exitosamente");
    alert("Cambios guardados exitosamente");
    setIsEditing(false);
    window.location.reload();
  } catch (error) {
    console.error("Error al guardar cambios:", error);
    alert("Error al guardar cambios");
  }
};

const handleApplyPriceIncrement2 = async () => {
  try {
    // Apply price increment for all selected products
    const editedProducts = Object.values(editingProducts);

    // Use Promise.all to wait for all axios.put requests to complete
    await Promise.all(editedProducts.map(async (product) => {

     
            await axios.put(`${HolaC.apiUrl}/${username}/products/increment-price2/${product._id}`, {

        priceIncrementType2: priceIncrementType2,
        priceIncrementValue2: priceIncrementValue2,
      });

      // Optionally, update the product in the local state after the increment
      // ...

      // Uncomment the following line if you want to apply the increment locally
      // applyPriceIncrement(product._id);
    }));

    console.log("Cambios guardados exitosamente");
    alert("Cambios guardados exitosamente");
    setIsEditing(false);
    window.location.reload();
  } catch (error) {
    console.error("Error al guardar cambios:", error);
    alert("Error al guardar cambios");
  }
};
  
  
  
  
  

  const handleInputChange = (e, field, productId) => {
    const updatedEditingProducts = { ...editingProducts };
    updatedEditingProducts[productId] = {
      ...updatedEditingProducts[productId],
      [field]: e.target.value,
    };
    setEditingProducts(updatedEditingProducts);
  };

  const handleProviderChange = (e) => {
    setSelectedProvider(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const containerStyle = {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(to bottom right, #6a5acd, #003366)',
    padding: '20px',
    borderRadius: '8px',
  };

  const titleStyle = {
    color: '#fff',
  };

  const tableStyle = {
    background: 'rgba(255, 255, 255, 0.0)',
    borderRadius: '8px',
    overflowY: filteredProducts.length > 4 ? 'scroll' : 'hidden',  // Cambié 'auto' a 'scroll'
    maxHeight: '600px',  // Ajusta la altura máxima según tus necesidades
  };

  const rowStyle = {
    borderBottom: '1px solid #ddd',
  };

  const imageStyle = {
    width: '50px',
    borderRadius: '50%',
  };

  return (
    <div className="container mt-5" style={containerStyle}>
      <h2 style={titleStyle}>Lista de Productos</h2>
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="nameSearch" className="form-label text-white">Buscar por Nombre:</label>
          <input
            type="text"
            className="form-control"
            id="nameSearch"
            value={nameSearchTerm}
            onChange={(e) => setNameSearchTerm(e.target.value)}
            placeholder="Ingrese el nombre del producto"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="skuSearch" className="form-label text-white">Buscar por SKU:</label>
          <input
            type="text"
            className="form-control"
            id="skuSearch"
            value={skuSearchTerm}
            onChange={(e) => setSkuSearchTerm(e.target.value)}
            placeholder="Ingrese el SKU del producto"
          />
        </div>
      </div>
      <div className="row mb-3">
  <div className="col-md-6">
    <label htmlFor="providerSearch" className="form-label text-white">Buscar por Proveedor:</label>
    <select
      className="form-select"
      id="providerSearch"
      value={selectedProvider}
      onChange={handleProviderChange}
    >
      <option value="">Todos los proveedores</option>
      {providers.map(provider => (
        <option key={provider.id} value={provider.id}>{provider.providerName}</option>
      ))}
    </select>
  </div>
  <div className="col-md-6">
    <label htmlFor="categorySearch" className="form-label text-white">Buscar por Categoría:</label>
    <select
      className="form-select"
      id="categorySearch"
      value={selectedCategory}
      onChange={handleCategoryChange}
    >
      <option value="">Todas las categorías</option>
      {categories.map(category => (
        <option key={category.id} value={category.id}>{category.categoryName}</option>
      ))}
    </select>
    <div className="mb-3">
        <label className="form-check-label text-white">
          <input
            type="checkbox"
            className="form-check-input"
            checked={showZeroStock}
            onChange={() => setShowZeroStock(!showZeroStock)}
          />
          Mostrar productos sin stock
        </label>
      </div>
      <div className="mb-3">
        <label className="form-check-label text-white">
          <input
            type="checkbox"
            className="form-check-input"
            checked={selectAll}
            onChange={handleSelectAll}
          />
          Seleccionar Todos
        </label>
      </div>
  </div>
      </div>
      <div className="mb-3">
        {Object.keys(selectedProducts).length > 0 && (
          <div>
            {isEditing ? (
              <>
                <button className="btn btn-success me-2" onClick={handleSaveChanges}>
                  Guardar Cambios
                </button>
                <button className="btn btn-danger me-2" onClick={() => setIsEditing(false)}>
                  Cancelar Edición
                </button>
              </>
            ) : (
              <>
                <button className="btn btn-primary me-2" onClick={handleEditSelectedProducts}>
                  Editar Producto(s)
                </button>
                <button className="btn btn-danger me-2" onClick={handleDeleteSelectedProducts}>
                  Eliminar Producto(s)
                </button>
                
              </>



            )}
            <div className="mb-3">
          <label className="form-label text-white">Incrementar Precio de costo:</label>
          <div className="input-group">
            <select
              className="form-select"
              value={priceIncrementType}
              onChange={handlePriceIncrementTypeChange}
            >
              <option value="amount">Por Monto</option>
              <option value="percentage">Porcentaje</option>
            </select>
            <input
              type="text"
              className="form-control"
              value={priceIncrementValue}
              onChange={handlePriceIncrementValueChange}
              placeholder="Ingrese el valor"
            />
            <button
              className="btn btn-primary"
              onClick={handleApplyPriceIncrement}
            >
              Aplicar
            </button>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label text-white">Incrementar Precio de venta:</label>
          <div className="input-group">
            <select
              className="form-select"
              value={priceIncrementType2}
              onChange={handlePriceIncrementTypeChange2}
            >
              <option value="amount">Por Monto</option>
              <option value="percentage">Porcentaje</option>
            </select>
            <input
              type="text"
              className="form-control"
              value={priceIncrementValue2}
              onChange={handlePriceIncrementValueChange2}
              placeholder="Ingrese el valor"
            />
            <button
              className="btn btn-primary"
              onClick={handleApplyPriceIncrement2}
            >
              Aplicar
            </button>
          </div>
        </div>
          </div>
        )}
      </div>
      <div style={{ overflowY: "auto", maxHeight: "400px" }}>
<table className="table table-container" style={tableStyle}>
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Stock</th>
            <th>Precio de venta</th>
            <th>Precio de costo</th>
            <th>SKU</th>
            <th>Proveedor</th>
            <th>Categoría</th>
            <th>Imagen</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map(product => (
            <tr key={product._id} style={rowStyle}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedProducts[product._id]}
                  onChange={() => handleProductSelection(product._id)}
                />
              </td>
              <td>
                {isEditing && selectedProducts[product._id] ? (
                  <input
                    type="text"
                    value={editingProducts[product._id]?.productName || ""}
                    onChange={(e) => handleInputChange(e, "productName", product._id)}
                  />
                ) : (
                  product.productName
                )}
              </td>
              <td>
                {isEditing && selectedProducts[product._id] ? (
                  <input
                    type="text"
                    value={editingProducts[product._id]?.stock || ""}
                    onChange={(e) => handleInputChange(e, "stock", product._id)}
                  />
                ) : (
                  product.stock
                )}
              </td>
              <td>
                {isEditing && selectedProducts[product._id] ? (
                  <input
                    type="text"
                    value={editingProducts[product._id]?.price || ""}
                    onChange={(e) => handleInputChange(e, "price", product._id)}
                  />
                ) : (
                  product.price
                )}
              </td>
              <td>
                {isEditing && selectedProducts[product._id] ? (
                  <input
                    type="text"
                    value={editingProducts[product._id]?.costPrice || ""}
                    onChange={(e) => handleInputChange(e, "costPrice", product._id)}
                  />
                ) : (
                  product.costPrice
                )}
              </td>
              <td>
                {isEditing && selectedProducts[product._id] ? (
                  <input
                    type="text"
                    value={editingProducts[product._id]?.sku || ""}
                    onChange={(e) => handleInputChange(e, "sku", product._id)}
                  />
                ) : (
                  product.sku
                )}
              </td>
              <td>
                {isEditing && selectedProducts[product._id] ? (
                  <select
                    value={editingProducts[product._id]?.provider || ""}
                    onChange={(e) => handleInputChange(e, "provider", product._id)}
                  >
                    {providers.map(provider => (
                      <option key={provider._id} value={provider._id}>{provider.name}</option>
                    ))}
                  </select>
                ) : (
                  <span>{product.provider}</span>
                )}
              </td>
              <td>
                {isEditing && selectedProducts[product._id] ? (
                  <select
                    value={editingProducts[product._id]?.category || ""}
                    onChange={(e) => handleInputChange(e, "category", product._id)}
                  >
                    {categories.map(category => (
                      <option key={category._id} value={category._id}>{category.name}</option>
                    ))}
                  </select>
                ) : (
                  <span>{product.category}</span>
                )}
              </td>
              <td><img src={`http://localhost:3007/${product.image}`} alt="Producto" style={imageStyle} /></td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
}

export default Mostrarproductos;
