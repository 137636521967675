import React, { useState, useEffect } from "react";
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useUser } from "../UserContext";
import HolaC from "../holac";

const Mgastoshoy = () => {
  const [gastos, setGastos] = useState([]);
  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    // Obtener los gastos del backend al cargar el componente
    axios.get(`${HolaC.apiUrl}/${username}/ghoy`)
      .then(response => {
        setGastos(response.data);
      })
      .catch(error => {
        console.error("Error al obtener los gastos:", error);
      });
  }, []);

  const sumaTotalGastos = gastos.length > 0 ? gastos.reduce((total, gasto) => total + gasto.monto, 0) : 0;

  return (
    <div>
      <h2>Gastos por hora del día de hoy ${sumaTotalGastos}</h2>
      <BarChart width={600} height={300} data={gastos}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="hora" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="monto" fill="#8884d8" />
      </BarChart>
    </div>
  );
};

export default Mgastoshoy;
