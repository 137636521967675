import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Abajo from '../abajo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faUndo, faCoins, faTags , faMobile, faMobileButton, faCancel, faBullseye,faMoneyBill} from '@fortawesome/free-solid-svg-icons';
import { useUser } from '../UserContext';
import ReactDOMServer from 'react-dom/server';
import CargarGasto from '../gastos/cargargasto/cargargasto';
import HolaC from "../holac";







const PuntoPantalla = ({ total, selectedProduct, onNewSale, discountType, discountValue, onDiscountTypeChange, onDiscountValueChange, setTotal, setSelectedProduct, reloadProducts, setReloadProducts, ...props}) => {
  const [displayValue, setDisplayValue] = useState('');
  const [currentOperand, setCurrentOperand] = useState('');
  const [result, setResult] = useState('');
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [selectedProductsList, setSelectedProductsList] = useState([]);
  const [extraItemName, setExtraItemName] = useState('');
  const [extraItemPrice, setExtraItemPrice] = useState(0);
  const [discountInputVisible, setDiscountInputVisible] = useState(false);
  const [isDiscountEditing, setIsDiscountEditing] = useState(false);
  const [showArticleInput, setShowArticleInput] = useState(false);
  const [newArticleName, setNewArticleName] = useState('');
const [newArticlePrice, setNewArticlePrice] = useState('');
const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
const [paymentMethodModalVisible, setPaymentMethodModalVisible] = useState(false);
const [PagarVisible, setPagarVisible] = useState(false);
const [amountPaid, setAmountPaid] = useState(0);
const [change, setChange] = useState(null);  // Initialize with 0
const [printableContent2, setPrintableContent2] = useState('');
const [printReceipt, setPrintReceipt] = useState(false);
const [showWhatsappForm, setShowWhatsappForm] = useState(false);
const [whatsappNumber, setWhatsappNumber] = useState('');
const [metodosPago, setMetodosPago] = useState([]);
const [selectedMetodoPago, setSelectedMetodoPago] = useState({});
const [mostrarCargarGasto, setMostrarCargarGasto] = useState(false);
const [saleCounter, setSaleCounter] = useState(1); // Initialize with 1 or the starting value
const [newSaleNumber, setSaleNumber] = useState(1); // Initialize with 1 or the starting value
const [vueltoValue, setVueltoValue] = useState("");
const [afip, setAfip] = useState(false);
const [cae, setCae] = useState("");
const [qr, setQr] = useState("");






useEffect(() => {
  const obtenerConexionAF = async () => {
    try {
      const response = await axios.get(`${HolaC.apiUrl}/${username}/obtenercon`);
      const { activarConexionAF } = response.data;
      if (activarConexionAF) {
        setAfip(true);
      } else {
        setAfip(false);
      }
    } catch (error) {
      console.error('Error al obtener la conexión AFIP:', error);
      setAfip(false); 
    }
  };

  obtenerConexionAF();
});










let printContent="";

const abrirCargarGasto = () => {
  setMostrarCargarGasto((prevMostrarCargarGasto) => !prevMostrarCargarGasto);
};




const handleEditQuantity = (index, newQuantity) => {
  if (newQuantity > 0) {
    const updatedList = [...selectedProductsList];
    const priceChange = (newQuantity - selectedProductsList[index].quantity) * selectedProductsList[index].price;
    
    // Actualizar la cantidad en la lista de productos
    updatedList[index].quantity = newQuantity;

    // Calcular el cambio en el total
    setTotal((prevTotal) => prevTotal + priceChange);

 
    // Actualizar la lista de productos
    setSelectedProductsList(updatedList);
    setReloadProducts(true);

  } else {
    alert('La cantidad debe ser mayor que 0');
  }
};


const generateSaleNumber = () => {
  const prefix = 'venta';
  const randomFourDigitNumber = Math.floor(1000 + Math.random() * 9000); // Genera un número aleatorio de 4 cifras
  const newSaleNumber = randomFourDigitNumber;
  setSaleNumber(newSaleNumber);
};















const abrirCargarGastohandlePrint = () => {


  
  const printWindow = window.open('', '_blank');

  // Copy the content of the Recibo component into the new window or iframe
  printWindow.document.write(`
    <html>
      <head>
        <title></title>
        <style>
          body {
            font-family: 'Courier New', Courier, monospace;
            margin: 0;
            padding: 10px;
          }
          #printableContent {
            width: 100%;
            max-width: 300px; /* Adjust the maximum width according to your ticket printer */
            margin: 0 auto;
          }
        </style>
      </head>
      <body>
        <div id="printableContent">
        <h3>Supermercados ${username}</h3>
        <h3>ticket no válido como factura</h3>
      ${printableContent2.replace(/\n/g, '<br />')} <!-- Replace newline characters with <br /> for spacing -->
        </div>
      </body>
    </html>
  `);

  // Call window.print() on the new window or iframe
  printWindow.print();

  // Optionally, you can close the new window or iframe
  printWindow.close();

  // Optionally, you can reset the state if needed
  setPrintReceipt(false);
};



const handlePrint  = async () => {
  try {
    const today = new Date().toLocaleString("es-AR", { timeZone: "America/Argentina/Buenos_Aires" });

    // Realizar una solicitud GET al servidor para obtener la configuración de AFIP
    const response = await axios.get(`${HolaC}/${username}/obtenerafip`);
    
    // Obtener el QR codificado en base64
    const qrBase64 = qr;
    // Realizar la decodificación base64
    const qrImage = 'data:image/png;base64,' + qrBase64;


    // Extraer los datos de configuración de AFIP de la respuesta
    const { cuit, nombreNegocio, nombrePropietario, dni, puntoVenta, tipoContribuyente, ingresosBrutos, fechaInicioActividades, domicilioComercial } = response.data;
  
    // Abrir una nueva ventana para imprimir el ticket
    const printWindow = window.open('', '_blank');
  
    // Escribir el contenido del ticket en la nueva ventana
    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
            body {
              font-family: 'Courier New', Courier, monospace;
              margin: 0;
              padding: 10px;
            }
            #printableContent {
              width: 100%;
              max-width: 300px; /* Ajusta el ancho máximo según tu impresora de tickets */
              margin: 0 auto;
            }
          </style>
        </head>
        <body>
          <div id="printableContent">
            <h3>Supermercados ${nombreNegocio}</h3>
            <h3>Ticket válido como factura</h3>
            <p>Razón Social: ${nombreNegocio}</p>
            <p>C.U.I.T.: ${cuit}</p>
            <p>Domicilio Comercial: ${domicilioComercial}</p>
            <p>${tipoContribuyente}</p>
            <p>Ingresos Brutos: ${ingresosBrutos}</p>
            <p> inicio de actividades: ${fechaInicioActividades}</p>

         <p>---------------------------------------------</p>
          <h1> FACTURA TIPO C </h1>
          <p>cógido 11</p>
          <p>Consumidor Final</p>
          <p>Fecha: ${today}</p> 
          <p>Concepto: Productos </p>
            ${printableContent2.replace(/\n/g, '<br />')} <!-- Replace newline characters with <br /> for spacing -->
          <p>-----------------------------------------------</p>
          <p>CAE: ${cae}</p>
          <img src="${qrImage}" alt="QR Code" style="width: 100%; max-width: 200px;">
           <p>Vencimiento :${today} </p> 
          </div>
        </body>
      </html>
    `);
  
    // Imprimir el contenido de la nueva ventana
    printWindow.print();
  
    // Cerrar la nueva ventana después de imprimir
    printWindow.close();
  } catch (error) {
    console.error('Error al imprimir el ticket:', error);
    // Manejar el error si la solicitud al servidor falla
  }
};











const { loggedInUser} = useUser();
  let username=loggedInUser;



  const handleWhatsappNumberChange = (e) => {
    setWhatsappNumber(e.target.value);
  };
//whatsapp

const showWhatsappFormHandler = () => {
  setShowWhatsappForm(true);
};

const showWhatsappFormHandler2 = () => {
  setShowWhatsappForm(false);
};

const handleEnviarWhatsappClick = async () => {
  if (!selectedProductsList || selectedProductsList.length === 0) {
    console.error('Error: No products selected');
    return;
  }

  let message = ''; // Initialize the message variable

  try {
    const response = await axios.get(`${HolaC.apiUrl}/${username}/obtenercon`);

    if (response.status === 200) {
      const configuracion = response.data;
      console.log('Configuración obtenida:', configuracion);

      // Build the message
      message =
        `*¡Muchas gracias por tu compra, te esperamos pronto en 🌟 ${configuracion.nombreLocal}!\n` +
        `🏠Dirección: ${configuracion.direccion}\n` +
        `📞Teléfono: ${configuracion.telefono}\n`;
    } else {
      console.error('Error al obtener la configuración:', response.data.message);
    }
  } catch (error) {
    console.error('Error al obtener la configuración:', error);
  }

  const ticketData = {
    products: selectedProductsList,
    extraItem: {
      name: extraItemName || 'Artículo Extra',
      price: extraItemPrice,
    },
    total: total,
    change: change,
    message1: message, // Include the message in ticketData
  };

  axios.post(`${HolaC.apiUrl}/${username}/wps`, {
    ticketData: ticketData,
    whatsappNumber: whatsappNumber,
  })
    .then(response => {
      console.log('WhatsApp message sent successfully:', response.data);
      setShowWhatsappForm(false);
    })
    .catch(error => {
      console.error('Error sending WhatsApp message:', error);
      alert("Error al enviar el mensaje de Whatsapp. Intente nuevamente");
    });
};




const amountPaidFloat = parseFloat(amountPaid);




const handleCalculateChange = () => {
  if (amountPaid !== '') {
    if (parseFloat(amountPaid) >= total) {
      const changeValue = amountPaidFloat - total;
      const totalCostPrice = selectedProductsList.reduce((total, product) => {
        return total + (product.cost * product.quantity);
      }, 0);
      generateSaleNumber();
      setChange(changeValue);

      axios.post(`${HolaC.apiUrl}/${username}/pay`, {
        productos: selectedProductsList.map(product => ({
          productName: product.productName,
          sku: product.sku,
          price: product.price,
          quantity: product.quantity,
          costPrice: product.costPrice
        })),
        metodoPago: selectedPaymentMethod,
        total: total,
        totalneto: totalCostPrice, 
        paynumber: newSaleNumber,
      })
      .then(response => {
        console.log('Respuesta del servidor:', response.data);

        // Después de la venta, actualiza el stock en el servidor
        axios.put(`${HolaC.apiUrl}/${username}/updateStock`, {
          productsToUpdate: selectedProductsList,
        })
        .then(() => {
          // Stock actualizado correctamente
          alert("Venta guardada correctamente");

          // Enviar los datos al API de AFIP
          axios.post(`${HolaC.apiUrl}/${username}/afip`, {
            productos: selectedProductsList,
            total: total,
          })
          .then(afipResponse => {
            console.log('Respuesta de AFIP:', afipResponse.data);
            setPrintReceipt(true);
            const cae = afipResponse.data.cae;
              const qrBase64 = afipResponse.data.qr;

            setCae(cae);
            setQr(qrBase64);
            console.log(cae);
            console.log(qrBase64);


          })
          .catch(error => {
            console.error('Error al enviar los datos a AFIP:', error);
            alert("Error al enviar los datos a AFIP");
            // Set the state and then call imprimirTicket
            setPrintReceipt(true);
          });
        })
        .catch(error => {
          console.error('Error al actualizar el stock:', error);
          alert("Error al actualizar el stock");
        });
      })
      .catch(error => {
        console.error('Error al procesar la venta:', error);
        alert("Error al procesar la venta");
      });
    } else {
      alert("Debes ingresar un número mayor al del total");
    }
  }
};




const NuevaVenta = () =>{


  setPagarVisible(false);

  resetTotalsAndProduct();

  setPrintableContent2("");
  

 


}



const WhatsAppm = () => (
  <div  className="modal" style={{ display: showWhatsappForm? 'block' : 'none'  }}>
  <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '1000px',maxHeight:'300px'}}>
    <div className="modal-content">
    <form className="mt-4">
    <div className="mb-3">
        <label htmlFor="whatsappNumber" className="form-label" style={color}>
          <FontAwesomeIcon icon={faMobileButton} style={{ marginRight: '5px' }} /> Número de WhatsApp:
        </label>
        <input
          type="text"
          className="form-control"
          id="whatsappNumber"
          value={whatsappNumber}
          autoFocus
          onChange={handleWhatsappNumberChange}
        />
      </div>
      <button
        type="button"
        onClick={handleEnviarWhatsappClick}
        className="btn btn-primary btn-lg m-1 rectangle"
        style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
      >
        <FontAwesomeIcon icon={faMobile} style={{ marginRight: '5px' }} /> Enviar WhatsApp
      </button>
    </form>
    <button
      onClick={showWhatsappFormHandler2}
      className="btn btn-primary btn-lg m-1 rectangle"
      style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
    >
      <FontAwesomeIcon icon={faCancel} style={{ marginRight: '5px' }} /> Cancelar
    </button>
  </div>
  </div>
</div>);









const PaymentMethodModal = () => (
  <div className="modal" style={{ display: paymentMethodModalVisible ? 'block' : 'none' }}>
    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px'}}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 style={color} className="modal-title">Seleccionar Medio de Pago</h5>
          <button type="button" className="btn-close" onClick={() => setPaymentMethodModalVisible(false)}></button>
        </div>
        <div className="modal-body" style={{ padding: '20px' }}>
            {metodosPago.map((metodoPago) => (
              <button
                key={metodoPago._id}
                onClick={() => handlePaymentMethodClick(metodoPago.nombre)}
                className="btn btn-success btn-lg m-1 rectangle"
                style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
              >
                {metodoPago.icono && <i className={metodoPago.icono}></i>} {metodoPago.nombre}
              </button>
            ))}
                  </div>

      </div>
    </div>
  </div>
);




const Pagar = () => (
  <div className="modal" style={{ display: PagarVisible ? 'block' : 'none' }}>
    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px'}}>
      <div className="modal-content">
        <div className="modal-header">
          <div className="mt-4 p-3" style={{ 
            background: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            display: 'block',
          }}>
            <h1 style={color}>Total a Pagar: ${total.toFixed(2)}</h1>
            <input
              type="text"
              placeholder="Monto Pagado"
              value={amountPaid}
              onChange={(e) => setAmountPaid(e.target.value)}
              autoFocus
              className="form-control"
              style={{ width: '80%', background: '#6a5acd', color: 'white' }}
            />
        <button
  onClick={() => {
    handleCalculateChange();
    imprimirTicket(amountPaid);  }}
  className="btn btn-success btn-lg m-1 rectangle"
  style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
>
  <FontAwesomeIcon icon={faCashRegister} /> Pagar
</button>
            <button
              onClick={NuevaVenta}
              className="btn btn-success btn-lg m-1 rectangle"
              style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
            >
              <FontAwesomeIcon icon={faUndo} /> Nueva Venta
            </button>
            <button
          onClick={handlePrint}
          className="btn btn-primary btn-lg m-1 rectangle"
          style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
            >
          <FontAwesomeIcon icon={faUndo} />Imprimir Recibo
        </button>

        {showWhatsappForm && (
      <WhatsAppm />  
    )}
        {change !== null && (
  <div className="rectangle" style={{ background: '#6a5acd', padding: '10px', borderRadius: '5px', marginTop: '10px' }}>
    <h1 className="white-text"> GRACIAS POR SU COMPRA!</h1>
    <h1 className="white-text">
      <FontAwesomeIcon icon={faCoins} />  Su Vuelto: ${change.toFixed(2)}

    </h1>
    <button
      onClick={showWhatsappFormHandler} 
      className="btn btn-primary btn-lg m-1 rectangle"
      style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
    >
      Enviar Whatsapp
    </button>

  </div>



            )}

          </div>
        </div>
      </div>
    </div>
  </div>
);
const handleRemoveProduct = (index) => {
  const removedProduct = selectedProductsList[index];
  // Resta el precio del producto eliminado al total
  setTotal((prevTotal) => prevTotal - removedProduct.price * removedProduct.quantity);

  // Elimina el producto de la lista
  setSelectedProductsList((prevList) => {
    const updatedList = [...prevList];
    updatedList.splice(index, 1);
    return updatedList;
  });

  // Set reloadProducts to true to trigger a reload
  setReloadProducts(true);
};



const color = {
  color: '#8e44ad', // Color lila
  textShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)' // Sombra para las letras
};

  useEffect(() => {
    handleAddToCart();
  }, [selectedProduct]);

  useEffect(() => {
    axios.get(`${HolaC.apiUrl}/${username}/obtenerMetodoPago`)
      .then(response => {
        setMetodosPago(response.data);
      })
      .catch(error => {
        console.error("Error fetching payment methods:", error);
      });
  }, []);
  

  const handleNumberClick = (number) => {
    if (currentOperand) {
      setDisplayValue(number.toString());
      setCurrentOperand('');
    } else {
      setDisplayValue((prevDisplay) => prevDisplay + number);
    }
  };

  const handleOperationClick = (operator) => {
    if (displayValue) {
      setCurrentOperand(operator);
      setDisplayValue((prevDisplay) => prevDisplay + ` ${operator} `);
    }
  };

  const handleEqualClick = () => {
    if (displayValue) {
      try {
        const result = eval(displayValue);
        setResult(result.toString());
        setDisplayValue(result.toString());
      } catch (error) {
        console.error('Error al evaluar la expresión:', error);
      }
    }
  };

  const handleDeleteClick = () => {
    setDisplayValue('');
    setResult('');
  };

  const handleProductPrice = (price) => {
    setSelectedPrices((prevPrices) => [...prevPrices, price]);
  };
  const handleAddToCart = () => {
    if (selectedProduct) {
      setSelectedProductsList((prevList) => {
        const existingProductIndex = prevList.findIndex(
          (product) => product.sku === selectedProduct.sku
        );
  
        if (existingProductIndex !== -1) {
          // If the product is already in the list, update quantity
          const updatedList = [...prevList];
          const updatedProduct = { ...updatedList[existingProductIndex] };
          updatedProduct.quantity += 1;
          updatedList[existingProductIndex] = updatedProduct;
  
          console.log('Updated List:', updatedList); // Log the updated list
  
          return updatedList;
        } else {
          // If the product is not in the list, add it as a new item
          console.log('Product added:', selectedProduct); // Log the added product
          return [
            ...prevList,
            {
              productName: selectedProduct.productName,
              sku: selectedProduct.sku,
              price: selectedProduct.price,
              quantity: 1,
              cost:selectedProduct.costPrice
            },
          ];
        }
      });
  
      setDisplayValue('');
      setResult('');
      // Reset the selectedProduct to null after adding it to the cart
      setSelectedProduct(null);
    }
  };
  
  
  
  
     

  const resetTotalsAndProduct = () => {
    // Reset state
    setSelectedPrices([]);
    setSelectedProductsList([]);
    setDisplayValue('');
    setResult('');

    // Reset selected product
    setSelectedProduct(null);

    // Reset extra item
    setExtraItemName('');
    setExtraItemPrice(0);
    setTotal(0);  // Set the total directly to the discounted total

    setAmountPaid(0);
    setChange(null)

    setReloadProducts(true);


    
  };
  const handleSaveExtraItem = () => {
    if (extraItemPrice > 0) {
      handleProductPrice(extraItemPrice);
      setSelectedProductsList((prevList) => [
        ...prevList,
        {
          productName: extraItemName || 'Artículo Extra',
          sku: 'extra',
          price: extraItemPrice,
        },
      ]);
      setTotal((prevTotal) => prevTotal + extraItemPrice);

  
      // Reinicia los valores
      setExtraItemName('');
      setExtraItemPrice(0);
      setShowArticleInput(false);
    }
  };
  const handlePaymentMethodClick = (method) => {
    setSelectedPaymentMethod(method);
    setPaymentMethodModalVisible(false);
    setPagarVisible(true);

    
    console.log('PagarVisible state:', PagarVisible);
  };






  const NewArticleInput = () => (
    <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
      <input
        type="text"
        placeholder="Nombre del Artículo"
        value={newArticleName}
        onChange={(e) => setNewArticleName(e.target.value)}
        className="form-control m-1"
        style={{ maxWidth: '200px', background: '#6a5acd', color: '#ffffff' }}
      />
      <input
        type="number"
        placeholder="Precio"
        value={newArticlePrice}
        onChange={(e) => setNewArticlePrice(e.target.value)}
        className="form-control m-1"
        style={{ maxWidth: '100px', background: '#6a5acd', color: '#ffffff' }}
      />
      <button
        onClick={handleSaveArticleClick}
        className="btn btn-warning btn-lg m-1 rectangle"
        style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
      >
        Guardar Cambios
      </button>
    </div>
  );


  const handleSaveArticleClick = () => {


    
    if (newArticlePrice > 0) {
      handleProductPrice(parseFloat(newArticlePrice));
      const newTotal = total + parseFloat(newArticlePrice);
      onNewSale();
      setSelectedProductsList((prevList) => [
        ...prevList,
        {
          productName: newArticleName || 'Artículo Extra',
          sku: 'extra',
          price: parseFloat(newArticlePrice),
        },
      ]);
  
      // Reinicia los valores
      setNewArticleName('');
      setNewArticlePrice('');
      setShowArticleInput(false);
      onNewSale(newTotal);
    }
  };

const imprimirTicket = async (amountPaid) =>{


 
    const totalAmount = selectedPrices.reduce((total, price) => total + price, 0);
    let vuelto= amountPaid - total;
 
    // Aplicar descuento
    let discountedTotal = totalAmount;
    let discountLabel = '';

    if (discountType === 'percentage') {
      discountedTotal *= 1 - discountValue / 100;
      discountLabel = `Descuento: ${discountValue}%`;
    } else {
      discountedTotal -= discountValue;
      discountLabel = `Descuento: $${discountValue.toFixed(2)}`;
    }

    

    printContent = `Ticket número : ${newSaleNumber}\n`;
     printContent += 'Productos seleccionados:\n';
    selectedProductsList.forEach((product, index) => {
      printContent += `  Nombre: ${product.productName}\n`;
      printContent += `  Precio: $ ${product.price.toFixed(2)}\n`;
      printContent += `  Cantidad: ${product.quantity}\n`;

    });
  
    // Agrega el Artículo Extra si existe
    if (extraItemPrice > 0) {
      printContent += 'Artículo Extra:\n';
      printContent += `  Nombre: ${extraItemName || 'Artículo Extra'}\n`;
      printContent += `  Precio: $${extraItemPrice.toFixed(2)}\n\n`;
    }
  
    printContent += `${discountLabel}\n`;
    printContent += `SU TOTAL ES $ ${total}\n`;
    printContent +=  `SU VUELTO ES $ ${vuelto}\n`;
    printContent += `Total después del descuento: $${discountedTotal.toFixed(2)}\n`;
    try {
      const response = await axios.get(`${HolaC.apiUrl}/${username}/obtenercon`);
  
      if (response.status === 200) {
        const configuracion = response.data;
        console.log('Configuración obtenida:', configuracion);
  

      } else {
        console.error('Error al obtener la configuración:', response.data.message);
      }
    } catch (error) {
      console.error('Error al obtener la configuración:', error);
    }









    setPrintableContent2(printContent, () => {
      console.log("printable content", printableContent2);
    });
    
  };










  



  const handlePayClick = async () => {
 
  





    setPaymentMethodModalVisible(true);

  
  };


  const handleNewSale = () => {
    let discountedTotal = total;
  
    if (discountType === 'percentage') {
      discountedTotal *= 1 - discountValue / 100;
    } else {
      discountedTotal -= discountValue;
    }
  
    setTotal(discountedTotal);  // Set the total directly to the discounted total
  };

  const handleClearProduct = () => {
    setDisplayValue('');
    setResult('');
  };

  const handleExtraItemClick = () => {
    setShowArticleInput(!showArticleInput); // Toggle the visibility
  };

  const handleDiscountButtonClick = () => {
    setDiscountInputVisible(!discountInputVisible);
    setIsDiscountEditing(true);
  };
  const handleSaveChangesClick = () => {
    setIsDiscountEditing(false);
    setShowArticleInput(showArticleInput); // Toggle the visibility


    // Aplicar descuento y actualizar el total
    let discountedTotal = total;

    if (discountType === 'percentage') {
      discountedTotal *= 1 - discountValue / 100;
    } else {
      discountedTotal -= discountValue;
    }

    // Realizar acciones adicionales si es necesario

    // Actualizar el total después del descuento
    setTotal(discountedTotal);
  };

  return (
    <div>
    <div className="container mt-4" style={{ 
      background: 'linear-gradient(to bottom right, #3a2a6d, #003366)',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 10px 8px rgba(0,0,0,0.1)',
    }}>
      <div className="mb-4 rectangle" style={{ 
        fontSize: '3em', 
        color: '#ffffff',
        marginTop: '10px', 
        fontFamily: 'Roboto, sans-serif',
        border: '1px solid #ccc', borderRadius: '10px', padding: '15px', textAlign: 'right', marginTop: '10px'
      }}>
        Total: ${(total + extraItemPrice).toFixed(2)}
      </div>

      {selectedProduct && (
        <div className="mb-4 rectangle" style={{  color: '#ffffff',fontSize: '1.4rem', border: '1px solid #ccc', borderRadius: '10px', padding: '15px', textAlign: 'right', marginTop: '10px' }}>
          Producto: {selectedProduct.productName}, SKU: {selectedProduct.sku}, Precio: ${selectedProduct.price.toFixed(2)}
        </div>
      )}

      <PaymentMethodModal />
      <Pagar />
      <WhatsAppm/>
      {mostrarCargarGasto && <CargarGasto />}






      <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
  <button
    onClick={handlePayClick}
    className="btn btn-success btn-lg m-1 rectangle"
    style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
  >
    <FontAwesomeIcon icon={faCashRegister} /> Pagar
  </button>

  <button onClick={abrirCargarGasto}      
  className="btn btn-success btn-lg m-1 rectangle"
  style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
>   <FontAwesomeIcon icon={faMoneyBill} /> Cargar Gasto</button>

  <button
    onClick={resetTotalsAndProduct}
    className="btn btn-danger btn-lg m-1 rectangle"
    style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
  >
    <FontAwesomeIcon icon={faUndo} /> Nueva Venta
  </button>
  <button
    onClick={handleDiscountButtonClick}
    className="btn btn-info btn-lg m-1 rectangle"
    style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
  >
    <FontAwesomeIcon icon={faTags} /> Descuento
  </button>
   


</div>

      {isDiscountEditing && (
        <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
          
                    <select
            value={discountType}
            onChange={(e) => onDiscountTypeChange(e.target.value)}
            className="form-select m-1"
            style={{ background: '#6a5acd', color: '#ffffff' }}
          >
            <option value="percentage">Porcentaje</option>
            <option value="amount">Monto Fijo</option>
          </select>
          <input
            type="number"
            placeholder={discountType === 'percentage' ? 'Porcentaje de Descuento' : 'Monto de Descuento'}
            value={discountValue}
            onChange={(e) => onDiscountValueChange(parseFloat(e.target.value))}
            className="form-control m-1"
            style={{ maxWidth: '150px', background: '#6a5acd', color: '#ffffff' }}
          />
          <button
            onClick={handleSaveChangesClick}
            className="btn btn-success btn-lg m-1 rectangle"
            style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
          >
            Guardar Cambios
          </button>
        </div>
      )}
            <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">

       <button
          onClick={handleExtraItemClick}
          className="btn btn-warning btn-lg m-1 rectangle"
          style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
        >
          Articulo
        </button>

       {showArticleInput && (
          <>
      <input
              type="text"
              placeholder="Nombre del Artículo"
              value={extraItemName}
              onChange={(e) => setExtraItemName(e.target.value)}
              className="form-control m-1"
              style={{ maxWidth: '200px', background: '#6a5acd', color: '#ffffff' }}
            />
            <input
              type="number"
              placeholder="Precio"
              value={extraItemPrice}
              onChange={(e) => setExtraItemPrice(parseFloat(e.target.value))}
              className="form-control m-1"
              style={{ maxWidth: '100px', background: '#6a5acd', color: '#ffffff' }}
            />
            <button
              onClick={handleSaveExtraItem}
              className="btn btn-warning btn-lg m-1 rectangle"
              style={{ borderRadius: '5px', background: '#6a5acd', color: '#ffffff' }}
            >
              Guardar Cambios
            </button>
        
       </>

       )}
              </div>

      <div className="container mt-4" style={{ 
    background: 'linear-gradient(to bottom right, #8e44ad, #3498db)',
    padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 10px 8px rgba(0,0,0,0.1)',
      overflowY: selectedProductsList.length > 2 ? 'auto' : 'hidden',  // Add overflowY property
      maxHeight: '600px',  // Set a maximum height to limit the scrollbar height
    }}>

          <div className="mt-4 p-3" style={{ 
            background: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }}>
            <h4 style={color}>Productos Seleccionados</h4>
            <ul>
              
            {selectedProductsList.map((product, index) => (
  <div key={index} className="d-flex justify-content-between">
    <div>
      <strong>{product.productName}</strong><br />
      <span>Precio: ${product.price.toFixed(2)}</span><br />
      <span>
                    Cantidad: 
                    <input
                      type="number"
                      value={product.quantity}
                      onChange={(e) => handleEditQuantity(index, parseInt(e.target.value))}
                      style={{ width: '50px' }}
                    />
                  </span><br />    </div>
    <div>
      <button
        onClick={() => handleRemoveProduct(index)}
        className="btn btn-danger btn-sm m-1 rectangle"
        style={{ borderRadius: '5px' }}
      >
        Eliminar
      </button>
    </div>
  </div>
))}
            </ul>
    
            {/* Display extra item if exists */}
            {extraItemPrice > 0 && (
              <div>
                <h4>Artículo Extra</h4>
                <span>Nombre: {extraItemName || 'Artículo Extra'}</span><br />
                <span>Precio: ${extraItemPrice.toFixed(2)}</span><br />
              </div>
            )}
          </div>
          </div>

      </div>

 
          </div>
    
  );
};

export default PuntoPantalla;
