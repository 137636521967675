import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { useUser } from '../UserContext';
import HolaC from "../holac";
import Mgastoshoy from '../graficos/mgastoshoy';
import MostrarGastos from '../graficos/mgastos';


const Cajas = () => {
  const [metodosPago, setMetodosPago] = useState([]);
  const [ventas, setVentas] = useState([]);
  const { loggedInUser } = useUser();
  let username = loggedInUser;

  useEffect(() => {
    // Obtener métodos de pago
    axios.get(`${HolaC.apiUrl}/${username}/obtenerMetodoPago`)
      .then(response => {
        setMetodosPago(response.data);
      })
      .catch(error => {
        console.error('Error al obtener los métodos de pago:', error);
      });

    // Obtener ventas del día de hoy
    const fechaHoy = new Date().toISOString().split('T')[0];

    axios.get(`${HolaC.apiUrl}/${username}/verpay2`)
      .then(response => {
        setVentas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener las ventas:', error);
      });
  }, []); // Dependencia vacía para ejecutar solo una vez al montar el componente

  // Función para generar datos para el gráfico de ventas por método de pago
  const generarDatosGraficoVentas = () => {
    const datos = [];

    // Iterar sobre los métodos de pago y contar las ventas para cada uno
    metodosPago.forEach(metodo => {
      const ventasMetodo = ventas.filter(venta => venta.metodoPago === metodo.nombre);

      datos.push({
        metodoPago: metodo.nombre,
        ventas: ventasMetodo.length,
      });
    });

    return datos;
  };

  const generarDatosGraficoCajas = () => {
    const datos = [];

    // Iterar sobre los métodos de pago y sumar el total para cada uno
    metodosPago.forEach(metodo => {
      const ventasMetodo = ventas.filter(venta => venta.metodoPago === metodo.nombre);

      // Calcular la ganancia total acumulada para el método de pago actual
      const gananciaTotal = ventasMetodo.reduce((total, venta) => total + venta.total, 0);

      datos.push({
        caja: metodo.nombre,
        ventas: gananciaTotal,
      });
    });

    return datos;
  };

  return (
    <div>
      <h2>MIS CAJAS</h2>

      <BarChart width={600} height={400} data={generarDatosGraficoVentas()}>
        <XAxis dataKey="metodoPago" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="ventas" fill="#82ca9d" />
      </BarChart>

      <BarChart width={600} height={400} data={generarDatosGraficoCajas()}>
        <XAxis dataKey="caja" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="ventas" fill="#7a5e8c" />
      </BarChart>
<Mgastoshoy/>
<MostrarGastos/>
      
    </div>
  );
};

export default Cajas;
