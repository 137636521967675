import React, { useState } from "react";
import './movil.css';
import { FaHome, FaShoppingCart, FaChartBar, FaMoneyBill } from 'react-icons/fa'; // Importar iconos de FontAwesome

const Menumovil = () => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="footer">
      <footer className="footer">
        <button
          className={`footer-button ${activeButton === "home" && "active"}`}
          onClick={() => handleButtonClick("home")}
        >
          <FaHome />
        </button>
        <button
          className={`footer-button ${activeButton === "cart" && "active"}`}
          onClick={() => handleButtonClick("cart")}
        >
          <FaShoppingCart />
        </button>
        <button
          className={`footer-button ${activeButton === "chart" && "active"}`}
          onClick={() => handleButtonClick("chart")}
        >
          <FaChartBar />
        </button>
        <button
          className={`footer-button ${activeButton === "money" && "active"}`}
          onClick={() => handleButtonClick("money")}
        >
          <FaMoneyBill />
        </button>
      </footer>
    </div>
  );
};

export default Menumovil;
