import React, { useEffect } from 'react';
import Menu from '../menu';
import Aos from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos CSS de AOS


import ProveedoresC from './proveedoresC';
import ProveedoresM from './proveedoresM';

const Proveedores= () => {
  useEffect(() => {
    // Inicializa AOS cuando el componente se monta
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="cargar-page">
      <div className="cargar-container" data-aos="fade-right">
        <Menu />
      </div>
      <ProveedoresM data-aos="fade-right"/>
      <div className="content">
        <ProveedoresC/>
        <div className="productos" data-aos="fade-right"></div>
      </div>
    </div>
  );
};

export default Proveedores;