import React, { useEffect } from 'react';
import Menu from '../menu';
import Aos from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos CSS de AOS

import Cargarproductos from './cargarproductos';
import Mostrarproductos from './mostrarproductos';

const Cargar = () => {
  useEffect(() => {
    // Inicializa AOS cuando el componente se monta
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="cargar-page">
      <div className="cargar-container" data-aos="fade-right">
        <Menu />
      </div>
      <Mostrarproductos data-aos="fade-right"/>
      <div className="content">
        <Cargarproductos />
        <div className="productos" data-aos="fade-right"></div>
      </div>
    </div>
  );
};

export default Cargar;
