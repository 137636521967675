import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import moment from 'moment';

import { useUser } from '../UserContext';
import HolaC from "../holac";


const texto1 = {
  color: 'white', // Establecer el color de texto a blanco
  textShadow: '1px 1px 2px rgba(0, 0, 0, 10)', // Agregar sombra al texto
  fontSize: '20px', // Aumentar el tamaño del texto a 24px
};
const Ventash = () => {
  const [ventasHoy, setVentasHoy] = useState([]);
  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    const fetchVentasHoy = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${username}/ventashoy`);
        setVentasHoy(response.data);
      } catch (error) {
        console.error('Error al obtener las ventas de hoy:', error);
      }
    };

    fetchVentasHoy();
  }, []);

  // Extraer datos para el gráfico
  const data = ventasHoy.map((venta) => ({
    name: moment(venta.createdAt).format('HH:mm'),
    total: venta.total,
  }));

  // Calcular el total de las ventas de hoy
  const totalVentasHoy = ventasHoy.reduce((total, venta) => total + venta.total, 0);

  return (
      <div style={{ marginRight: '20px' }}>
        <BarChart
          width={400}
          height={300}
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total" fill="#8884d8" barSize={30} shadow={{ color: 'rgba(0, 0, 0, 0.1)', offset: 2, blur: 4 }} />
        </BarChart>
        <p style={{ textAlign: 'center' }}>Total por horas</p>
        <h3 style={{ textAlign: 'center', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
        <p style={texto1}>Ventas del día de hoy</p>
        Total hoy: $ {totalVentasHoy}
        </h3>
        <br></br>
        <br></br>
      </div>
  );
};

export default Ventash;
