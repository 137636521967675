import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Abajo from '../abajo';
import { useUser } from '../UserContext';
import HolaC from "../holac";


const PuntoProductos = ({ handleProductPrice, handleProductSelection, onRemoveProductFromPuntoProductos, updateStock }) => {
  const [products, setProducts] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [searchSKU, setSearchSKU] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [searchProvider, setSearchProvider] = useState('');
  const [categories, setCategories] = useState([]);
  const [providers, setProviders] = useState([]);

  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    axios.get(`${HolaC.apiUrl}/${username}/products1`)
      .then(response => setProducts(response.data))
      .catch(error => console.error('Error fetching products:', error));

    axios.get(`${HolaC.apiUrl}/${username}/categories`)
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));

    axios.get(`${HolaC.apiUrl}/${username}/providers`)
      .then(response => setProviders(response.data))
      .catch(error => console.error('Error fetching providers:', error));
  }, []);

  const handleProductClick = (product) => {
    // Verificar si el producto tiene stock antes de manejar el clic
    if (product.stock > 0) {
      handleProductPrice(product.price);
      handleProductSelection(product);
  
      // Resta 1 al stock del producto seleccionado
      setProducts((prevProducts) => {
        const updatedProducts = prevProducts.map((prevProduct) => {
          if (prevProduct._id === product._id) {
            return { ...prevProduct, stock: Math.max(0, prevProduct.stock - 1) };
          }
          return prevProduct;
        });
        return updatedProducts;
      });
    } else {
      // Puedes mostrar un mensaje o realizar alguna acción cuando el stock es 0
      alert('Este producto está fuera de stock.');
    }
  };
 

  const handleBlur = (sku) => {
    const matchedProduct = products.find((product) => product.sku === sku);
    if (matchedProduct) {
      // Verificar si el producto tiene stock antes de manejar el evento onBlur
      if (matchedProduct.stock > 0) {
        handleProductPrice(matchedProduct.price);
        handleProductSelection(matchedProduct);
      } else {
        alert('Este producto está fuera de stock y no puede ser seleccionado para la venta.');
      }
    } else {
      alert('Producto no encontrado');
    }
  };

  console.log(products);
  console.log("searchName:", searchName);
  console.log("searchSKU:", searchSKU);
  console.log("searchCategory:", searchCategory);
  console.log("searchProvider:", searchProvider);
  const filteredProducts = products.filter(product =>
    (searchName === '' || product.productName.toLowerCase().includes(searchName.toLowerCase())) &&
    (searchSKU === '' || (typeof product.sku === 'string' && product.sku.includes(searchSKU))) &&
    (searchCategory === '' || product.category.toLowerCase().includes(searchCategory.toLowerCase())) &&
    (searchProvider === '' || product.provider.toLowerCase().includes(searchProvider.toLowerCase()))
  );
  console.log("filteredProducts:", filteredProducts);


  const headingStyle = {
    textAlign: 'center',
    marginBottom: '10px',
    color: '#ffffff',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Adding text shadow
  };

  const containerStyle = {
    background: 'linear-gradient(to bottom right, #8e44ad, #3498db)',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  };

  return (      <div className="container mt-4" style={containerStyle}>
       <h1 style={headingStyle}>PUNTO DE VENTA</h1>
 

    <div className="container mt-4" style={{ backgroundColor: '#e6e6fa', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>

      <div className="row mb-3">
        <div className="col-md-6">
          <input
            type="text"
            placeholder="Buscar por nombre"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-6">
  <input
    type="text"
    placeholder="Buscar por SKU"
    value={searchSKU}
    onChange={(e) => {
      setSearchSKU(e.target.value);
      handleBlur(e.target.value);

      // Delay the reset of searchSKU for 1 second (adjust as needed)
      setTimeout(() => {
        setSearchSKU('');
      }, 1000);
    }}
    className="form-control"
  />
</div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <select
            value={searchCategory}
            onChange={(e) => setSearchCategory(e.target.value)}
            className="form-select mb-2"
          >
            <option value="">Seleccione Categoría</option>
            {categories.map(category => (
              <option key={category._id} value={category.categoryName}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <select
            value={searchProvider}
            onChange={(e) => setSearchProvider(e.target.value)}
            className="form-select mb-2"
          >
            <option value="">Seleccione Proveedor</option>
            {providers.map(provider => (
              <option key={provider._id} value={provider.providerName}>
                {provider.providerName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div style={{ overflowY: "auto", maxHeight: "400px" }}>


      <div className="d-flex flex-wrap justify-content-around">
        {filteredProducts.map((product) => (
          <div
            key={product._id}
            onClick={() => handleProductClick(product)}
            className="product-card"
            style={{
              backgroundColor: '#fff',
              padding: '15px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              marginBottom: '10px',
              cursor: 'pointer',
            }}
          >
            <h4>{product.productName}</h4>
            <p>SKU: {product.sku}</p>
            <p>Precio: ${product.price.toFixed(2)}</p>
            <p>Stock: {product.stock}</p>
          </div>
          
        ))}
                  </div>
      </div>
    </div>

    </div>
    
  );
};

export default PuntoProductos;
