import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useUser } from "../../UserContext";
import HolaC from "../../holac";

const Mgastos = () => {
  const [gastos, setGastos] = useState([]);
  const [selectedCajeroId, setSelectedCajeroId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleGastos, setVisibleGastos] = useState(11); // Número de resultados visibles
  const tableRef = useRef(null);
  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    // Obtener los gastos al cargar el componente
    axios.get(`${HolaC.apiUrl}/${username}/ogastos`)
      .then(response => {
        setGastos(response.data);
      })
      .catch(error => {
        console.error("Error fetching gastos:", error);
      });
  }, []);

  const handleCajeroSelection = (cajeroId) => {
    setSelectedCajeroId(cajeroId);
  };

  const handleDeleteSelectedCajero = async () => {
    try {
      if (!selectedCajeroId) {
        alert("Seleccione un gasto para eliminar.");
        return;
      }
  
      await axios.delete(`${HolaC.apiUrl}/${username}/gastos/${selectedCajeroId}`);
      
      console.log("Gasto eliminado exitosamente");
      alert("Gasto eliminado exitosamente");
      setSelectedCajeroId(null);
      window.location.reload();
    } catch (error) {
      console.error("Error al eliminar el gasto", error);
      alert("Error al eliminar el gasto");
    }
  };

  // Filtrar los gastos por el término de búsqueda
  const filteredGastos = gastos.filter(gasto =>
    (gasto.gastoName?.toLowerCase() || '').includes(searchTerm.toLowerCase())
  );
  const containerStyle = {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(to bottom right, #6a5acd, #003366)',
    padding: '20px',
    borderRadius: '8px',
  };

  

  return (
    <div className="container mt-5" style={containerStyle}>
      <h2 style={titleStyle}>Gastos</h2>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Buscar gasto por nombre"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div style={{ overflowY: "auto", maxHeight: "400px" }}>
      <table className="table" ref={tableRef}>
        <thead>
          <tr>
            <th>Nombre Gasto</th>
            <th>Monto</th>
            <th>Tipo de Pago</th>
            <th>Fecha</th>
            <th>Hora</th>
          </tr>
        </thead>
        <tbody>
          {filteredGastos.map(gasto => (
            <tr key={gasto._id}>
              <td>{gasto.gastoName}</td>
              <td>{gasto.monto}</td>
              <td>{gasto.tipoPago}</td>
              <td>{gasto.fecha}</td>
              <td>{gasto.hora}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    
    </div>
  );
};

const titleStyle = {
  color: "#000",
  fontSize: "30px",
};

export default Mgastos;
