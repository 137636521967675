import React, { useState, useEffect } from "react";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import moment from "moment";
import { useUser } from "../UserContext";
import HolaC from "../holac";


const VentasMensual = () => {
  const [selectedMonth, setSelectedMonth] = useState(moment().format("YYYY-MM"));
  const [ventasMensuales, setVentasMensuales] = useState([]);
  const [totalVentasMensual, setTotalVentasMensual] = useState(0);
  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    const fetchVentasMensuales = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${username}/ventasMensuales/${selectedMonth}`);
        setVentasMensuales(response.data);
  
        // Calcular el total de ventas mensual
        const total = response.data.reduce((acc, venta) => {
          const ventaTotal = parseFloat(venta.total); // Convertir el total a número
          if (!isNaN(ventaTotal)) {
            return acc + ventaTotal;
          } else {
            return acc;
          }
        }, 0);
  
        setTotalVentasMensual(total);
      } catch (error) {
        console.error("Error al obtener las ventas mensuales:", error);
      }
    };
  
    fetchVentasMensuales();
  }, [selectedMonth]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // Extraer datos para el gráfico
  const data = ventasMensuales.map((venta) => ({
    day: moment(venta.createdAt).format("DD"),
    total: venta.total,
  }));

  return (
    <div style={{ marginRight: '10px' }}>
      <label htmlFor="monthSelector">Seleccione un mes:</label>
      <select id="monthSelector" value={selectedMonth} onChange={handleMonthChange}>
        <option value="2024-01">Enero 2024</option>
        <option value="2024-02">Febrero 2024</option>
      </select>

      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="total" fill="#8884d8" barSize={20} shadow={{ color: "rgba(0, 0, 0, 0.1)", offset: 2, blur: 4 }} />
      </BarChart>

      <h3 style={{ textAlign: "center", color: "#8884d8", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}>
        <p>Total ventas por dia del mes</p>
        Total mensual: $ {totalVentasMensual}
      </h3>
    </div>
  );
};

export default VentasMensual;
