import React, { useEffect } from 'react';
import Menu from '../menu';
import Aos from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos CSS de AOS

import Metodoc1 from './metodoc';
import Metodopago1 from './metodopago';
const Metodo2= () => {
  useEffect(() => {
    // Inicializa AOS cuando el componente se monta
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="cargar-page">
      <div className="cargar-container" data-aos="fade-right">
        <Menu />
      </div>
      <Metodopago1 data-aos="fade-right"/>
      <div className="content">

<Metodoc1/>
        <div className="productos" data-aos="fade-right"></div>
      </div>
    </div>
  );
};

export default Metodo2;