import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../UserContext';
import HolaC from "../holac";


const ProveedoresC = () => {
  const [providerName, setProviderName] = useState('');
  const [providerAddress, setProviderAddress] = useState('');
  const [providerCategory, setProviderCategory] = useState('none');
  const [providerPhone, setProviderPhone] = useState('');
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    // Cargar las categorías al montar el componente
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${username}/categories`);
        setCategories(response.data);
        setLoadingCategories(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('providerName', providerName);
      formData.append('providerAddress', providerAddress);
      formData.append('providerCategory', providerCategory);
      formData.append('providerPhone', providerPhone);
      formData.append('image', image);

      const response = await axios.post(`${HolaC.apiUrl}/${username}/providers`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Provider saved successfully!', response.data);
      alert("Proveedor guardado exitosamente");
      window.location.reload();

    } catch (error) {
      console.error('Error saving provider:', error);
      alert("Error al guardar el proveedor");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    } else {
      setImage(null);
    }
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === 'x') {
      handleSubmit(e);
    }
  };

  return (
    <div className="alargar" style={{ width: '80%', margin: '0 auto' }}>
      <div className="nuevo">
        <h1 className="text-white">Cargar Proveedor</h1>

        {loadingCategories ? (
          <p>Cargando categorías...</p>
        ) : (
          <form onSubmit={handleSubmit} className="row g-3">
            <div className="col-md-6">
              <label htmlFor="providerName" className="form-label text-white">Nombre del Proveedor:</label>
              <input
                type="text"
                className="form-control"
                id="providerName"
                value={providerName}
                onChange={(e) => setProviderName(e.target.value)}
                required
              />

              <label htmlFor="providerAddress" className="form-label text-white">Dirección del Proveedor:</label>
              <input
                type="text"
                className="form-control"
                id="providerAddress"
                value={providerAddress}
                onChange={(e) => setProviderAddress(e.target.value)}
                required
              />

              <label htmlFor="providerPhone" className="form-label text-white">Teléfono del Proveedor:</label>
              <input
                type="text"
                className="form-control"
                id="providerPhone"
                value={providerPhone}
                onChange={(e) => setProviderPhone(e.target.value)}
                required
              />
            </div>

            <div className="col-md-6">
  <label htmlFor="providerCategory" className="form-label text-white">Categoría del Proveedor:</label>
  <select
    className="form-select"
    id="providerCategory"
    value={providerCategory}
    onChange={(e) => setProviderCategory(e.target.value)}
  >
    <option value="none">Ninguna</option>
    {categories.map((category) => (
      <option key={category._id} value={category.categoryName}>
        {category.categoryName}
      </option>
    ))}
  </select>
</div>

            <div className="col-12 mt-3">
              <button type="submit" className="btn btn-primary">Guardar Proveedor</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ProveedoresC;
