import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../UserContext";
import HolaC from "../holac";



const Metodopago1 = () => {
  const { loggedInUser } = useUser();
  const [metodosPago, setMetodosPago] = useState([]);
  const [selectedMetodoPago, setSelectedMetodoPago] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  let username = loggedInUser;

  const containerStyle = {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(to bottom right, #6a5acd, #003366)',
    padding: '20px',
    borderRadius: '8px',
  };

  const titleStyle = {
    color: '#fff',
    fontSize: '50px', 
  };

  const tableStyle = {
    background: 'rgba(255, 255, 255, 0.0)',
    borderRadius: '8px',
  };

  const rowStyle = {
    borderBottom: '1px solid #ddd',
  };

  const imageStyle = {
    width: '50px',
    borderRadius: '50%',
  };

  useEffect(() => {
    // Obtener los métodos de pago al cargar el componente
    axios.get(`${HolaC.apiUrl}/${username}/obtenerMetodoPago`)
      .then(response => {
        setMetodosPago(response.data);
      })
      .catch(error => {
        console.error("Error fetching payment methods:", error);
      });
  }, []);

  const handleMetodoPagoSelection = (metodoPagoId) => {
    if (isEditing) {
      // Lógica de selección en modo edición
      const isSelected = selectedMetodoPago[metodoPagoId];
      const updatedSelectedMetodoPago = { ...selectedMetodoPago, [metodoPagoId]: !isSelected };
      setSelectedMetodoPago(updatedSelectedMetodoPago);
    } else {
      // Lógica de selección normal
      const isSelected = selectedMetodoPago[metodoPagoId];
      const updatedSelectedMetodoPago = { ...selectedMetodoPago, [metodoPagoId]: !isSelected };
      setSelectedMetodoPago(updatedSelectedMetodoPago);
    }
  };

  const handleDeleteSelectedMetodoPago = async () => {
    try {
      const selectedIds = Object.keys(selectedMetodoPago);

      if (selectedIds.length === 0) {
        alert("Seleccione al menos un método de pago para eliminar.");
        return;
      }

      // Utiliza el primer ID de los métodos de pago seleccionados, puedes ajustar esto según tus necesidades
      const firstMetodoPagoId = selectedIds[0];

      await axios.delete(`${HolaC.apiUrl}/${username}/eliminarMetodoPago/${firstMetodoPagoId}`);
console.log(firstMetodoPagoId)
      console.log("Método de pago eliminado exitosamente");
      alert("Método de pago eliminado exitosamente");
      setSelectedMetodoPago({});
      window.location.reload();
    } catch (error) {
      console.error("Error al eliminar método de pago:", error);
      alert("Error al eliminar método de pago");
    }
  };

  return (
    <div className="container mt-5" style={containerStyle}>
  
      <h2 style={titleStyle}>Métodos de Pago </h2>
      <br></br>
      <div className="mb-3">
        <button className="btn btn-danger me-2" onClick={handleDeleteSelectedMetodoPago}>
          Eliminar Método(s) de Pago
        </button>
        <br></br>

      </div>
      <div style={{ overflowY: "auto", maxHeight: "400px" }}>
      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>
          {metodosPago.map(metodoPago => (
            <tr key={metodoPago._id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedMetodoPago[metodoPago._id]}
                  onChange={() => handleMetodoPagoSelection(metodoPago._id)}
                />
              </td>
              <td>{metodoPago.nombre}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
}

export default Metodopago1;
