import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../UserContext";
import HolaC from "../holac";


const ProveedoresM = () => {
  const [providers, setProviders] = useState([]);
  const [nameSearchTerm, setNameSearchTerm] = useState("");
  const [categorySearchTerm, setCategorySearchTerm] = useState("");
  const [showAll, setShowAll] = useState(true);
  const [selectedProviders, setSelectedProviders] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [editingProviders, setEditingProviders] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    axios.get(`${HolaC.apiUrl}/${username}/providers`)
      .then(response => {
        setProviders(response.data);
      })
      .catch(error => {
        console.error("Error fetching providers:", error);
      });
  }, []);

  const filteredProviders = providers.filter(provider =>
    provider.providerName.toLowerCase().includes(nameSearchTerm.toLowerCase()) &&
    provider.providerCategory.toLowerCase().includes(categorySearchTerm.toLowerCase()) &&
    (showAll ? true : provider.providerAddress !== 'none')
  );

  const handleProviderSelection = (providerId) => {
    if (isEditing) {
      const isSelected = selectedProviders[providerId];
      const updatedSelectedProviders = { ...selectedProviders, [providerId]: !isSelected };
      setSelectedProviders(updatedSelectedProviders);

      const selectedProvidersInEditMode = { ...editingProviders };

      if (updatedSelectedProviders[providerId]) {
        // If selected but not in edit list, add to the list with current data
        if (!selectedProvidersInEditMode[providerId]) {
          selectedProvidersInEditMode[providerId] = { ...providers.find(provider => provider._id === providerId) };
        }
      } else {
        // If not selected, remove from the edit list
        delete selectedProvidersInEditMode[providerId];
      }

      setEditingProviders(selectedProvidersInEditMode);
    } else {
      // If not in edit mode, change provider selection
      const isSelected = selectedProviders[providerId];
      const updatedSelectedProviders = { ...selectedProviders, [providerId]: !isSelected };
      setSelectedProviders(updatedSelectedProviders);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedSelectedProviders = {};

    filteredProviders.forEach(provider => {
      updatedSelectedProviders[provider._id] = !selectAll;

      if (!selectAll && !editingProviders[provider._id]) {
        // If not selected and not in edit list, add to the list with current data
        editingProviders[provider._id] = { ...provider };
      } else if (selectAll && editingProviders[provider._id]) {
        // If selected and in edit list, remove from the edit list
        delete editingProviders[provider._id];
      }
    });

    setSelectedProviders(updatedSelectedProviders);
    setEditingProviders(editingProviders);
  };

  const handleEditSelectedProviders = () => {
    setIsEditing(true);
  };

  const handleSaveChanges = async () => {
    try {
      const editedProviders = Object.values(editingProviders);
      await Promise.all(editedProviders.map(provider =>
        axios.put(`${HolaC.apiUrl}/${username}/providers/${provider._id}`, provider)
      ));

      console.log("Changes saved successfully");
      alert("Changes saved successfully");
      setIsEditing(false);
      window.location.reload();
    } catch (error) {
      console.error("Error saving changes:", error);
      alert("Error saving changes");
    }
  };

  const handleDeleteSelectedProviders = async () => {
    try {
      const selectedIds = Object.keys(selectedProviders);
  
      if (selectedIds.length === 0) {
        alert("Select at least one provider to delete.");
        return;
      }
  
      await axios.delete(`${HolaC.apiUrl}/${username}/providers`, {
        data: { selectedProviderIds: selectedIds },
      });
  
      console.log("Providers deleted successfully");
      alert("Providers deleted successfully");
      setSelectedProviders({});
      window.location.reload();
    } catch (error) {
      console.error("Error deleting providers:", error);
      alert("Error deleting providers");
    }
  };
  




  const handleInputChange = (e, field, providerId) => {
    const updatedEditingProviders = { ...editingProviders };
    updatedEditingProviders[providerId] = {
      ...updatedEditingProviders[providerId],
      [field]: e.target.value,
    };
    setEditingProviders(updatedEditingProviders);
  };

  const containerStyle = {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(to bottom right, #6a5acd, #003366)',
    padding: '20px',
    borderRadius: '8px',
  };

  const titleStyle = {
    color: '#fff',
  };

  const tableStyle = {
    background: 'rgba(255, 255, 255, 0.0)',
    borderRadius: '8px',
  };

  const rowStyle = {
    borderBottom: '1px solid #ddd',
  };

  return (
    <div className="container mt-5" style={containerStyle}>
      <h2 style={titleStyle}>Proveedores</h2>
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="nameSearch" className="form-label text-white">Buscar por Nombre:</label>
          <input
            type="text"
            className="form-control"
            id="nameSearch"
            value={nameSearchTerm}
            onChange={(e) => setNameSearchTerm(e.target.value)}
            placeholder="Ingrese el nombre del proveedor"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="categorySearch" className="form-label text-white">Buscar por Categoría:</label>
          <input
            type="text"
            className="form-control"
            id="categorySearch"
            value={categorySearchTerm}
            onChange={(e) => setCategorySearchTerm(e.target.value)}
            placeholder="Ingrese la categoría del proveedor"
          />
        </div>
      </div>
      <div className="mb-3">
        <label className="form-check-label text-white">
          <input
            type="checkbox"
            className="form-check-input"
            checked={selectAll}
            onChange={handleSelectAll}
          />
          Seleccionar Todos
        </label>
      </div>
      <div className="mb-3">
        {Object.keys(selectedProviders).length > 0 && (
          <div>
            {isEditing ? (
              <>
                <button className="btn btn-success me-2" onClick={handleSaveChanges}>
                  Guardar Cambios
                </button>
              </>
            ) : (
              <>
                <button className="btn btn-primary me-2" onClick={handleEditSelectedProviders}>
                  Editar Proveedor(es)
                </button>
                <button className="btn btn-danger me-2" onClick={handleDeleteSelectedProviders}>
                  Eliminar Proveedor(es)
                </button>
              </>
            )}
          </div>
        )}
      </div>
      <div style={{ overflowY: "auto", maxHeight: "400px" }}>

      <table className="table" style={tableStyle}>
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Dirección</th>
            <th>Categoría</th>
            <th>Teléfono</th>
          </tr>
        </thead>
        <tbody>
          {filteredProviders.map(provider => (
            <tr key={provider._id} style={rowStyle}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedProviders[provider._id]}
                  onChange={() => handleProviderSelection(provider._id)}
                />
              </td>
              <td>
                {isEditing && selectedProviders[provider._id] ? (
                  <input
                    type="text"
                    value={editingProviders[provider._id]?.providerName || ""}
                    onChange={(e) => handleInputChange(e, "providerName", provider._id)}
                  />
                ) : (
                  provider.providerName
                )}
              </td>
              <td>
                {isEditing && selectedProviders[provider._id] ? (
                  <input
                    type="text"
                    value={editingProviders[provider._id]?.providerAddress || ""}
                    onChange={(e) => handleInputChange(e, "providerAddress", provider._id)}
                  />
                ) : (
                  provider.providerAddress
                )}
              </td>
              <td>
                {isEditing && selectedProviders[provider._id] ? (
                  <input
                    type="text"
                    value={editingProviders[provider._id]?.providerCategory || ""}
                    onChange={(e) => handleInputChange(e, "providerCategory", provider._id)}
                  />
                ) : (
                  provider.providerCategory
                )}
              </td>
              <td>
                {isEditing && selectedProviders[provider._id] ? (
                  <input
                    type="text"
                    value={editingProviders[provider._id]?.providerPhone || ""}
                    onChange={(e) => handleInputChange(e, "providerPhone", provider._id)}
                  />
                ) : (
                  provider.providerPhone
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
  </div>
    </div>
  );
}

export default ProveedoresM;
