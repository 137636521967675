import { React, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import HolaC from './holac';



const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [currentImage, setCurrentImage] = useState(0);
  const images = ['/gen1.png', '/gen2.png', '/gen3.png']; // Rutas de tus imágenes


  const { setLoggedInUser } = useUser();
  const navigate = useNavigate();


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Cambia la imagen cada 5 segundos (ajusta según tus necesidades)

    return () => clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
  }, [images]);

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log(username, password);

    try {
      const response = await axios.post(`${HolaC.apiUrl}/hola`, {
       
        username,
        password,
      });

      if (response.status === 200) {
        // Guarda el token JWT en localStorage
        localStorage.setItem('token', response.data.token);

        // Guarda el usuario en localStorage
        localStorage.setItem('user', username);

        setLoggedInUser(username);

        // Guarda el usuario en el estado o donde lo necesites

        alert(response.data.message);
        navigate(`/app/${username}/administrador`);
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert(error.response.data.error);
      } else if (error.response && error.response.status === 404) {
        alert(error.response.data.error);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div
    className={`login-page ${currentImage !== 0 ? 'transition' : ''}`}
    style={{
      backgroundImage: `url(${images[currentImage]})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh',
      opacity: 0.7,
    }}
  >
      <Container className="mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div
              className="login-container"
              style={{

                backgroundColor: '#6a5acd',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img
                src="/geniusgif.gif"
                alt="Logo"
                className="img-fluid mb-4"
                style={{ borderRadius: '50%' }}
              />
              <Form onSubmit={handleLogin}>
                <Form.Group controlId="formUsername">
                  <Form.Label
                    style={{
                      color: '#ffffff',
                      fontFamily: 'Noto Sans, sans-serif',
                      fontWeight: 500,
                    }}
                  >
                    Usuario
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa tu usuario"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Label
                    style={{
                      color: '#ffffff',
                      fontFamily: 'Noto Sans, sans-serif',
                      fontWeight: 500,
                    }}
                  >
                    Contraseña
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Ingresa tu contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <br />

                <Button
                  variant="primary"
                  type="submit"
                  style={{
                    backgroundColor: '#4b0082',
                    borderColor: '#4b0082',
                    padding: '12px 20px',
                    transition: 'background-color 0.3s',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#003366';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '#4b0082';
                  }}
                >
                  Iniciar sesión
                </Button>
              </Form>

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
