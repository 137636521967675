import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PieChart, Pie, Tooltip, Legend } from 'recharts';
import { useUser } from '../UserContext';
import HolaC from "../holac";


const GraficoGananciaBruta = () => {
  const [ventasHoy, setVentasHoy] = useState([]);
  const [gastosHoy, setGastosHoy] = useState([]);
  const [gananciaBruta, setGananciaBruta] = useState(0);
  const [ventas, setVentas] = useState("");
  const [gastos, setGastos] = useState("");


  const { loggedInUser} = useUser();
  let username=loggedInUser;

  useEffect(() => {
    const fetchVentasHoy = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${username}/ventashoy`);
        setVentasHoy(response.data);
      } catch (error) {
        console.error('Error fetching sales today:', error);
      }
    };

    const fetchGastosHoy = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${username}/ghoy`);
        setGastosHoy(response.data);
      } catch (error) {
        console.error('Error fetching expenses today:', error);
      }
    };

    fetchVentasHoy();
    fetchGastosHoy();
  }, []);

  useEffect(() => {
    // Calcular la suma total de las ventas de hoy
    const totalVentas = ventasHoy.reduce((total, venta) => total + venta.total, 0);
    setVentas(totalVentas)

    // Calcular la suma total de los gastos de hoy
    const totalGastos = gastosHoy.reduce((total, gasto) => total + gasto.monto, 0);

    // Calcular la ganancia bruta (ventas - gastos)
    const gananciaBrutaCalculada = totalVentas - totalGastos;

    setGananciaBruta(gananciaBrutaCalculada);
    setVentas(totalVentas)
    setGastos(totalGastos)

  }, [ventasHoy, gastosHoy]);
 console.log(ventasHoy)



  // Datos para el gráfico de torta
  const datosTorta = [
    { name: 'Ventas', value: ventas, fill: '#8884d8' },
    { name: 'Gastos', value: gastos, fill: '#82ca9d' },
  ];

  return (
    <div>
      <PieChart width={400} height={400}>
        <Pie
          data={datosTorta}
          dataKey="value"
          nameKey="name"
          fill="#8884d8"
          label
        />
        <Tooltip />
        <Legend />
      </PieChart>
      <h3 style={{ textAlign: "center", color: "#8884d8", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}>
        <p>Total hoy Ganancia bruta</p>
      <h2>Total ${gananciaBruta.toFixed(2)}</h2>

      </h3>
    </div>
  );
};

export default GraficoGananciaBruta;
