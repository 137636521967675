import React from "react";
import './movil.css';
import Menumovil from "./Menumovil";
import Cajasmovil from "./cajasmovil";
const Movil = () => {
  return (
<div className="movil-container ">
<Cajasmovil></Cajasmovil>
<div className="content1">
</div>
<div className="footer">
<Menumovil></Menumovil>
</div>
</div>


  );
};

export default Movil;
