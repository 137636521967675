import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../UserContext';
import HolaC from "../holac";


const CajeroC = () => {
  const [cajeroName, setCajeroName] = useState('');
  const [cajeroPassword, setCajeroPassword] = useState('');
  const { loggedInUser} = useUser();

  const username=loggedInUser;
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post(`${HolaC.apiUrl}/${username}/cajero`, {
        cajeroName,
        cajeroPassword,
      });
  
      console.log('Cajero saved successfully!', response.data);
      alert('Cajero guardado exitosamente');
      window.location.reload();
    } catch (error) {
      console.error('Error saving Cajero:', error);
      alert('Error al guardar el Cajero');
    }
  };

  return (
    <div className="alargar" style={{ width: '80%', margin: '0 auto' }}>
      <div className="nuevo">
        <br />
        <br />
        <h1 className="text-white">Cargar Cajero</h1>

        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-6">
            <label htmlFor="CajeroName" className="form-label text-white">Nombre del Cajero:</label>
            <input
              type="text"
              className="form-control"
              id="CajeroName"
              value={cajeroName}
              onChange={(e) => setCajeroName(e.target.value)}
              required
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="CajeroPassword" className="form-label text-white">Contraseña del Cajero:</label>
            <input
              type="password"
              className="form-control"
              id="CajeroPassword"
              value={cajeroPassword}
              onChange={(e) => setCajeroPassword(e.target.value)}
              required
            />
          </div>

          <div className="col-12 mt-3">
            <button type="submit" className="btn btn-primary">Guardar Cajero</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CajeroC;
