import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../UserContext';
import HolaC from "../holac";


const Cargarproductos = () => {
  const [productName, setProductName] = useState('');
  const [stock, setStock] = useState('');
  const [price, setPrice] = useState('');
  const [costPrice, setCostPrice] = useState('');
  const [sku, setSku] = useState('');
  const [provider, setProvider] = useState('none');
  const [image, setImage] = useState(null);
  const [providers, setProviders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loadingProviders, setLoadingProviders] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [category, setCategory] = useState('none');
  const [incrementType, setIncrementType] = useState('porcentaje');
  const [incrementValue, setIncrementValue] = useState('');

  const { loggedInUser } = useUser();
  let username = loggedInUser;

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${username}/providers`);
        setProviders(response.data);
        setLoadingProviders(false);
      } catch (error) {
        console.error('Error fetching providers:', error);
        setLoadingProviders(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${username}/categories`);
        setCategories(response.data);
        setLoadingCategories(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoadingCategories(false);
      }
    };

    fetchProviders();
    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let newPrice;

      if (incrementType === 'porcentaje') {
        newPrice = parseFloat(costPrice) + (parseFloat(costPrice) * parseFloat(incrementValue) / 100);
      } else {
        newPrice = parseFloat(price);
      }

      const formData = new FormData();
      formData.append('productName', productName);
      formData.append('stock', stock);
      formData.append('price', newPrice);
      formData.append('costPrice', costPrice);
      formData.append('sku', sku);
      formData.append('provider', provider);
      formData.append('image', image);
      formData.append('category', category);
      formData.append('incrementType', incrementType);
      formData.append('incrementValue', incrementValue);

      const response = await axios.post(`${HolaC.apiUrl}/${username}/products`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Product saved successfully!', response.data);
      alert("Producto guardado exitosamente");
      window.location.reload();
    } catch (error) {
      console.error('Error saving product:', error);
      alert("Error al guardar el producto");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    } else {
      setImage(null);
    }
  };

  const handleIncrementTypeChange = (type) => {
    setIncrementType(type);
  };

  return (
    <div className="alargar" style={{ width: '80%', margin: '0 auto' }}>
      <div className="nuevo">
        <h1 className="text-white">Cargar Productos</h1>

        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-6">
            <label htmlFor="productName" className="form-label text-white">Nombre del Producto:</label>
            <input
              type="text"
              className="form-control"
              id="productName"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />

            <label htmlFor="costPrice" className="form-label text-white">Precio de Costo:</label>
            <input
              type="number"
              className="form-control"
              id="costPrice"
              value={costPrice}
              onChange={(e) => setCostPrice(e.target.value)}
              required
            />

            <label htmlFor="sku" className="form-label text-white">SKU:</label>
            <input
              type="text"
              className="form-control"
              id="sku"
              value={sku}
              onChange={(e) => setSku(e.target.value)}
              required
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="stock" className="form-label text-white">Stock:</label>
            <input
              type="number"
              className="form-control"
              id="stock"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              required
            />

            {incrementType === 'porcentaje' && (
              <div>
                <label htmlFor="incrementValue" className="form-label text-white">Porcentaje a Incrementar:</label>
                <input
                  type="number"
                  className="form-control"
                  id="incrementValue"
                  value={incrementValue}
                  onChange={(e) => setIncrementValue(e.target.value)}
                  required
                />
              </div>
            )}

            {incrementType === 'fijo' && (
              <div>
                <label htmlFor="price" className="form-label text-white">Precio de venta:</label>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </div>
            )}

            <div className="btn-group mt-3" role="group">
              <button
                type="button"
                className={`btn ${incrementType === 'porcentaje' ? 'btn-primary' : 'btn-secondary'}`}
                onClick={() => handleIncrementTypeChange('porcentaje')}
              >
                Porcentaje
              </button>
              <button
                type="button"
                className={`btn ${incrementType === 'fijo' ? 'btn-primary' : 'btn-secondary'}`}
                onClick={() => handleIncrementTypeChange('fijo')}
              >
                Costo Fijo
              </button>
            </div>

            <label htmlFor="provider" className="form-label text-white">Proveedor:</label>
            <select
              className="form-select"
              id="provider"
              value={provider}
              onChange={(e) => setProvider(e.target.value)}
            >
              <option value="none">Ninguno</option>
              {loadingProviders ? (
                <option value="" disabled>Cargando proveedores...</option>
              ) : (
                providers.map((provider) => (
                  <option key={provider._id} value={provider.providerName}>
                    {provider.providerName}
                  </option>
                ))
              )}
            </select>

            <label htmlFor="category" className="form-label text-white">Categoría:</label>
            <select
              className="form-select"
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="none">Ninguna</option>
              {loadingCategories ? (
                <option value="" disabled>Cargando categorías...</option>
              ) : (
                categories.map((category) => (
                  <option key={category._id} value={category.categoryName}>
                    {category.categoryName}
                  </option>
                ))
              )}
            </select>
          </div>

          <div className="col-12">
            <label htmlFor="image" className="form-label text-white">Imagen:</label>
            <input
              type="file"
              className="form-control"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
            />

            {image && (
              <div className="mt-3">
                <img
                  src={URL.createObjectURL(image)}
                  alt="Preview"
                  style={{ width: '100%', height: 'auto', maxHeight: '200px' }}
                />
              </div>
            )}
          </div>

          <div className="col-12 mt-3">
            <button type="submit" className="btn btn-primary">Guardar Producto</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Cargarproductos;
