import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import Menu from './menu';
import Ventashoy from './graficos/ventashoy';
import { useUser } from './UserContext';
import Aos from 'aos';
const Administrador = () => {

  const {username} =useUser();
  console.log(username);

  useEffect(() => {
    // Inicializa AOS cuando el componente se monta
    Aos.init({ duration: 2000 });
  }, []);



  return (
    <div className="cargar-page">

      <div className="cargar-container" data-aos="fade-right">
        <Menu />
      </div>
      <div className="content">
        <Ventashoy/>
        

        <div className="productos" data-aos="fade-right"></div>

      </div>
    </div>
  );
};

export default Administrador;