import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../../UserContext';
import HolaC from '../../holac';


const CargarGasto = () => {
  const [monto, setMonto] = useState('');
  const [selectedGasto, setSelectedGasto] = useState('');
  const [tipoPago, setTipoPago] = useState('');
  const [gastos, setGastos] = useState([]);
  const [nombreGastoVarios, setNombreGastoVarios] = useState('');


  const fechaActual = new Date();
  const formattedFecha = fechaActual.toISOString().split('T')[0];
  const horaActual = new Date().toLocaleTimeString('es-AR', { timeZone: 'America/Argentina/Buenos_Aires', hour12: false });


  const { loggedInUser} = useUser();

  const username=loggedInUser;


  useEffect(() => {
    // Obtener los gastos al cargar el componente
    axios.get(`${HolaC.apiUrl}/${username}/gasto2`)
      .then(response => {
        setGastos(response.data);
      })
      .catch(error => {
        console.error("Error fetching gastos:", error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Configura el objeto que se enviará al servidor
      const data = {
        monto,
        gastoName: selectedGasto,
        nombreGastoVarios,
        tipoPago,
        fecha: formattedFecha,
        hora: horaActual
      };

      // Envía la información al servidor
      const response = await axios.post(`${HolaC.apiUrl}/${username}/cargargasto`, data);

      console.log('Gasto guardado exitosamente!', response.data);
      window.location.reload();

      // Puedes realizar otras acciones después de guardar, como redireccionar o mostrar un mensaje de éxito
    } catch (error) {
      console.error('Error al guardar el gasto:', error);
      alert('Error al guardar el gasto:', error);
      // Manejar el error, mostrar un mensaje al usuario, etc.
    }
  };







  
  return (
    <div className="alargar" style={{ width: '80%', margin: '0 auto' }}>
      <div className="nuevo">
        <h1>Cargar Gasto</h1>
        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-6">
            <label htmlFor="monto" className="form-label text-white">Monto:</label>
            <input
              type="number"
              id="monto"
              value={monto}
              onChange={(e) => setMonto(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="gasto" className="form-label text-white">Nombre del Gasto:</label>
            <select
              id="gasto"
              value={selectedGasto}
              onChange={(e) => setSelectedGasto(e.target.value)}
              required
              className="form-select"
            >
              <option value="" disabled>-- Seleccionar --</option>
              {gastos.map((gasto) => (
                <option key={gasto._id} value={gasto.gastoName2}>
                  {gasto.gastoName2}
                </option>
              ))}
            </select>
            {selectedGasto === 'varios' && (
              <div className="mt-3">
                <label htmlFor="nombreGastoVarios" className="form-label text-white">Nombre del Gasto (Varios):</label>
                <input
                  type="text"
                  id="nombreGastoVarios"
                  value={nombreGastoVarios}
                  onChange={(e) => setNombreGastoVarios(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="tipoPago" className="form-label text-white">Tipo de Pago:</label>
            <select
              id="tipoPago"
              value={tipoPago}
              onChange={(e) => setTipoPago(e.target.value)}
              required
              className="form-select"
            >
              <option value="" disabled>-- Seleccionar --</option>
              <option value="efectivo">Efectivo</option>
              <option value="transferencia">Transferencia</option>
              <option value="cuentaCorriente">Cuenta Corriente</option>
              <option value="debito">Débito</option>
              <option value="tarjetaCredito">Tarjeta de Crédito</option>
            </select>
          </div>
          <div className="col-12 mt-3">
            <button type="submit" className="btn btn-primary">Guardar Gasto</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CargarGasto;
