import React, { useState, useEffect } from "react";
import axios from "axios";
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";
import { useUser } from "../UserContext";
import HolaC from "../holac";


const VentasNetasTotal = () => {
  const [ventasTotales, setVentasTotales] = useState([]);
  const [ventasNetas, setVentasNetas] = useState([]);
  const [totalVentasTotales, setTotalVentasTotales] = useState(0);
  const [totalVentasNetas, setTotalVentasNetas] = useState(0);
  const { loggedInUser} = useUser();
  let username=loggedInUser;


  useEffect(() => {
    const fetchVentasTotales = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${username}/ventashoy`);
        setVentasTotales(response.data);

        // Calcular el total de las ventas totales
        const total = response.data.reduce((accumulator, venta) => accumulator + venta.total, 0);
        setTotalVentasTotales(total);
      } catch (error) {
        console.error('Error al obtener las ventas totales:', error);
      }
    };

    const fetchVentasNetas = async () => {
      try {
        const response = await axios.get(`${HolaC.apiUrl}/${username}/ventas-hoy`);
        setVentasNetas(response.data);

        // Calcular el total de las ventas netas
        const total = response.data.reduce((accumulator, venta) => accumulator + venta.totalneto, 0);
        setTotalVentasNetas(total);
      } catch (error) {
        console.error('Error al obtener las ventas netas:', error);
      }
    };

    fetchVentasTotales();
    fetchVentasNetas();
  }, []);

  // Datos para el gráfico de torta
  const datosTorta = [
    { name: 'Ventas Totales', value: totalVentasTotales, fill: '#8884d8' },
    { name: 'Ventas Netas', value: totalVentasNetas, fill: '#82ca9d' },
  ];

  return (
    <div>
      <PieChart width={400} height={400}>
        <Pie
          data={datosTorta}
          dataKey="value"
          nameKey="name"
          fill="#8884d8"
          label
        />
        <Tooltip />
        <Legend />
      </PieChart>
      <h3 style={{ textAlign: "center", color: "#8884d8", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}>
        <p>Total ganancia neta</p>
      <h2>Ganancia Neta ${totalVentasTotales.toFixed(2)-totalVentasNetas.toFixed(2)}</h2>
      </h3>
    </div>
  );
};

export default VentasNetasTotal;
