import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos CSS de AOS
import Menu from '../../menu';
import CargarGasto from './cargargasto';
import Mgastos from './mgastos';
import MostrarGastos from '../../graficos/mgastos';
import Mgastoshoy from '../../graficos/mgastoshoy';

const CG = () => {
  useEffect(() => {
    // Inicializa AOS cuando el componente se monta
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="cargar-page">
      <div className="cargar-container" data-aos="fade-right">
        <Menu />
     
      </div>
  
      <div  className="content">
        <Mgastos />
        <br></br>
        <MostrarGastos />
      </div>
  
      <div className="content">
        <CargarGasto />
        <div className="productos" data-aos="fade-right"></div>
      </div>
    </div>
  );
  
};

export default CG;
